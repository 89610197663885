<template>
  <!-- max-height="calc(100vh - 100px - 30px - 60px - 50px - 30px)" -->
  <el-table 
  :data="data.value"
  :cell-style="{paddingLeft:'15px',color:'#161616',background:'#F5F5F5',fontWeight:'400'}" 
  :header-cell-style="{paddingLeft:'15px',paddingTop:'20px',paddingBottom:'20px', background:'#EDEDED', color:'#1D1D1D'}"
  >
    <el-table-column prop="id" label="ID"/>
    <el-table-column prop="sportName" label="Sport"/>
    <el-table-column prop="season" label="Season"/>
    <!-- <el-table-column prop="cid" label="Comp ID"/> -->
    <el-table-column prop="username" label="Nickname" width="180"/>
    <el-table-column prop="score" label="Score"/>
    <el-table-column prop="rank" label="Rank"/>
    <el-table-column fixed="right" label="Operations" width="350">
      <template #default="scope">
        <el-button class="operation-text" type="primary" color="#EDEDED" size="small" @click="router.push(`/tip/${scope.row.id}`)">Tip</el-button>
        <el-button class="operation-text" type="primary" color="#EDEDED" size="small" @click="express(scope.row.id, scope.row.cid, scope.row.season)">Express Tip</el-button>
        <el-button class="operation-text" type="primary" color="#EDEDED" size="small" @click="detail(scope.row.cid)">Detail</el-button>
        <el-button class="operation-text" type="primary" color="#EDEDED" size="small" @click="router.push(`/result/${scope.row.id}`)">Result</el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-dialog v-model="dialogDetailVisible" title="Tipping Details" width="800">
    <el-descriptions
      title="Competition Info"
      direction="vertical"
      :column="4"
      :border="true"
    >
      <el-descriptions-item label="Comp ID" 
      width="150px" 
      label-class-name="my-label" 
      class-name="my-content"
      >{{ detailInfo?.cid }}</el-descriptions-item>

      <el-descriptions-item label="Type" 
      width="300px"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ detailInfo?.type }}</el-descriptions-item>
      
      <el-descriptions-item label="Entry Fee" :span="2"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ detailInfo?.entryFee }}</el-descriptions-item>
    </el-descriptions>

    <el-descriptions
      title="Admin Info"
      direction="vertical"
      :column="4"
      :border="true"
      style="margin-top: 15px;"
    >
      <el-descriptions-item label="Admin ID" width="150px"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ detailInfo?.admin.id }}</el-descriptions-item>

      <el-descriptions-item label="Name" width="300px"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ detailInfo?.admin.username }}</el-descriptions-item>

      <el-descriptions-item label="Contact" :span="2"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ detailInfo?.admin.phone }}</el-descriptions-item>

      <el-descriptions-item label="Mail Address"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ detailInfo?.admin.email }}</el-descriptions-item>
    </el-descriptions>
  </el-dialog>

</template>

<script lang="ts" setup>
import { nextTick, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'
import type { Action } from 'element-plus'

import selectionApi from '@/api/selection'
import competitionJoinApi from '@/api/competitionJoin'

const route = useRoute();
const router = useRouter();

const data = defineProps(['value']);
const dialogDetailVisible = ref(false);
const detailInfo = ref();

const detail = (cid:number) => {
  competitionJoinApi.competitionConfirm({cid:cid}).then((res) => {
    if (res && res.code == 1) {
      detailInfo.value = res.response;
      dialogDetailVisible.value = true;
      // console.log(detailInfo.value);
    }
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
  });
}

const autoSave = (cuid:number, cid:number, season:number) => {
  let query = {
    cuid:cuid,
    cid:cid,
    season:season
  };
  selectionApi.autoSave(query).then(res => {
    if (res && res.code == 1) {
      // ElNotification.success({
      //   title: 'Success',
      //   // message: h('h1', { style: 'color: #3DA9FC' }, 'Sign Up Successfully'),
      //   message: 'Save Successfully',
      // });
      ElMessage({
        type: 'success',
        message: 'Express tip completed',
      });
    }
    else if (res && res.code == 201) {
      // ElMessageBox.alert('Please rank teams first in the My Ladder section', 'Rejected', {
      //   // if you want to disable its autofocus
      //   // autofocus: false,
      //   confirmButtonText: 'OK',
      //   callback: (action: Action) => {
      //   }
      // });
      handleLadder();
    }
    else {
      ElMessage({
        type: 'error',
        message: res.message,
      });
    }
    // console.log(res);
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err.message,
    });
    // console.log(err);
  });
}

const handleLadder = () => {
  ElMessageBox.confirm(
    'Please rank teams first in the My Ladder section',
    'Warning',
    {
      confirmButtonText: 'Rank teams',
      cancelButtonText: 'Stay here',
      type: 'warning',
    }
  ).then(() => {
    router.push('ladder');
  })
  .catch(() => {
    ElMessage({
      type: 'warning',
      message: 'Express tip canceled',
    });
  });
}

const express = (cuid:number, cid:number, season:number) => {

  ElMessageBox.confirm(
    'This operation will change your tip. Continue?',
    'Warning',
    {
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel',
      type: 'warning',
    }
  ).then(() => {
    autoSave(cuid,cid,season);
  })
  .catch(() => {
    ElMessage({
      type: 'warning',
      message: 'Express tip canceled',
    });
  });
}

</script>

<style>
.my-label {
  color: #5F6C7B !important;
  font-size: 14px !important;
  line-height: 14px !important;
  font-weight: 400 !important;
}
.el-descriptions__title {
  color: #5F6C7B;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
}
</style>

<style scoped>
.operation-text {
  /* text-decoration: underline; */
  /* color: #094067; */
  font-size: 12px;
  color: #1D1D1D;
  border-radius: 33px;
}
</style>