<template>
  <div class="form-container flex-column">
    <div class="form-content-container" v-loading="loading" element-loading-background="rgba(245, 245, 245, 0.3)">
      <!-- <h1 class="form-header flex" style="margin: 0 0 15px 5px;">COMPETITION INFO</h1>
      <el-row class="form-content flex" style="margin: 0 0 10px 5px; font-weight: 300;">
        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
          <h1 class="link-text flex" >
            COMP ID:
            <div class="form-text">{{ data?.cid }}</div>
          </h1>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
          <h1 class="link-text flex" >
            Entry Fee:
            <div class="form-text">{{ data?.entryFee }}</div>
          </h1>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="6">
          <h1 class="link-text flex" >
            Type:
            <div class="form-text">{{ data?.type }}</div>
          </h1>
        </el-col>
      </el-row> -->
      <h1 class="form-header flex" style="margin: 0 0 15px 5px;">ADMIN INFO</h1>
      <el-row class="form-content flex" style="margin: 0 0 10px 5px; font-weight: 300;">
        <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="6">
          <h1 class="link-text flex" >
            ADMIN ID:
            <div class="form-text">{{ data?.admin.id }}</div>
          </h1>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="6">
          <h1 class="link-text flex" >
            Name:
            <div class="form-text">{{ data?.admin.username }}</div>
          </h1>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="6">
          <h1 class="link-text flex" >
            Contact:
            <div class="form-text">{{ data?.admin.phone }}</div>
          </h1>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="6">
          <h1 class="link-text flex" >
            Mail:
            <div class="form-text">{{ data?.admin.email }}</div>
          </h1>
        </el-col>
      </el-row>
      <h1 class="form-header flex" style="margin: 30px 0 15px 5px;">PARTICIPANT INFO</h1>
      <el-row class="form-content flex" style="margin: 0 0 10px 5px; font-weight: 300;">
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="8">
          <h1 class="link-text flex" >
            Username:
            <div class="form-text">{{ form?.value.username }}</div>
          </h1>
        </el-col>
        <!-- <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="6">
          <h1 class="link-text flex" >
            Gender:
            <div class="form-text">{{ form?.value.gender }}</div>
          </h1>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="6">
          <h1 class="link-text flex" >
            Age:
            <div class="form-text">{{ form?.value.age }}</div>
          </h1>
        </el-col> -->
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="8">
          <h1 class="link-text flex" >
            Mobile:
            <div class="form-text">{{ form?.value.mobile }}</div>
          </h1>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="8">
          <h1 class="link-text flex" >
            Mail:
            <div class="form-text">{{ form?.value.email }}</div>
          </h1>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
          <h1 class="link-text flex" >
            Address:
            <div class="form-text">{{ form?.value.address }}</div>
          </h1>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="6">
          <h1 class="link-text flex" >
            City:
            <div class="form-text">{{ form?.value.city }}</div>
          </h1>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="6">
          <h1 class="link-text flex" >
            State:
            <div class="form-text">{{ form?.value.state }}</div>
          </h1>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="8" :xl="6">
          <h1 class="link-text flex" >
            Postcode:
            <div class="form-text">{{ form?.value.postcode }}</div>
          </h1>
        </el-col>
      </el-row>
      <!-- <el-row class="form-content flex" style="margin: 0 0 10px 5px; font-weight: 300;">
      </el-row>
      <el-row class="form-content flex" style="margin: 0 0 10px 5px; font-weight: 300;">
      </el-row> -->
    </div>
    <div class="form-footer-container flex">
      <el-button type="primary" class="submit-btn" color="#5200FF" @click="emits('paymentEvent', -1);" :disabled="loading">PREV</el-button>
      <el-button type="primary" class="submit-btn" color="#5200FF" @click="submit" :disabled="loading">NEXT</el-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router';
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import competitionJoinApi from '@/api/competitionJoin'
import tippingApi from '@/api/tipping'
import paymentApi from '@/api/payment'


const route = useRoute();
const competitionId = route.params.competition;

const emits = defineEmits(['paymentEvent']);

const data = ref();
const form = defineProps(['value']);
const loading = ref(true);

const saveTipping = async () => {
  form.value.cid = competitionId;
  try {
    const res = await tippingApi.save(form.value);
    return res.response;
  } catch (err) {

    return null;
  }
}

const getSession = async (cuid:number) => {
  try {
    const res:any = await paymentApi.creatSession({cid:competitionId, cuid:cuid});
    if (res && res.code == 1) {
      sessionStorage.setItem(`${competitionId}-session`, res.response.sessionId);
      return res.response.sessionUrl;
      // window.location.href = res.response.sessionUrl;
    }
    return null;
    // console.log(res);
  }
  catch (err) {
    
    return null;
  }
}

const submit = () => {
  ElMessageBox.confirm(
    'By clicking this, the info you provided cannot be changed later.',
    'Info',
    {
      confirmButtonText: 'Pay',
      cancelButtonText: 'Double check',
      type: 'info',
    }
  ).then(async () => {
    loading.value = true;
    const cuid = await saveTipping();
    if (cuid != null) {
      const sessionUrl = await getSession(cuid);
      emits('paymentEvent', 1, {cuid:cuid,sessionUrl:sessionUrl});
      window.open(sessionUrl);
    }
    loading.value = false;
    // router.push('ladder');
  })
  .catch((err) => {
    loading.value = false;
    ElMessage({
      type: 'warning',
      message: 'Pay link canceled',
    });
  });
}

onBeforeMount(async () => {
  competitionJoinApi.competitionConfirm({cid:competitionId}).then(res => {
    if (res && res.code == 1) {
      data.value = res.response;
    }
    loading.value = false;
    // console.log(res);
  }).catch(err => {
    loading.value = false;
    // console.log(err);
  });

});

</script>

<style scoped>
.form-container {
  justify-content: space-between;
  height: calc(100vh - 156px - 50px - 30px - 115px - 30px - 92px - 20px);
  min-height: calc(650px - 30px - 115px - 30px - 92px - 20px);
}
.form-text {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: #161616;
  margin: 0 0 0 15px;
  align-items: center;
  text-align: left;
}
.form-content {
  /* justify-content: space-between; */
  width: 100%;
}
.form-header {
  color: #161616;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin: 0;
  text-align: left;
  align-items: flex-end;
}
.link-text {
  margin: 15px 0 0 0;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  color: #8A8A8A;
  align-items: center;
  margin-right: 10px;
}

.form-footer-container {
  padding: 0px 30px 10px 30px;
  /* border-top: 2px solid #D9D9D9; */
  justify-content: space-between;
}
.form-content-container {
  padding: 15px 30px 0 30px;
}
.submit-btn {
  width: 200px;
  height: 50px;
  font-size: 18PX;
  font-weight: 500;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  color: #F5F5F5;
  border-radius: 12px;
}
</style>