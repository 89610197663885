<template>
  <div class="row-container flex">
    <h1 class="row-name flex">
      <el-image class="img" :src="require('@/assets/teams/logos/' + data.value.name + '.png')" fit="cover" />
      {{data.value.name}}
    </h1>
    <div class="streak-container flex">
      <StreakCard v-for="item in data.value.streak" :value="item"></StreakCard>
    </div>
  </div>

</template>

<script lang="ts" setup>
import { defineProps, ref } from 'vue'

import StreakCard from '@/components/ladder/StreakCard.vue'

const data = defineProps(['value']);

</script>

<style scoped>
.img {
  border-radius: 7px;
  height: 30px;
  width: 40px;
  /* border-radius: 5px;
  background-color: #f5f5f5; */
  margin-right: 10px;
  user-select: none;
}
.streak-container {
  width: 140px;
  align-items: center;
  justify-content: space-between;
}
.row-name {
  color: #161616;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  margin: 0;
  text-align: left;
  align-items: center;
  user-select: none;
}
.row-container {
  height: 45px;
  /* padding: 0 5px; */
  justify-content: space-between;
  border-bottom: 1px solid #D9D9D9;
}
</style>