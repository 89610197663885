<template>
  <div class="panel-header-container flex">
    <h1 class="panel-header flex">
      Latest Result
    </h1>
    <!-- <h1 class="panel-header-link flex">
      check all results
      <el-icon style="font-size: 10px;"><Right /></el-icon>
    </h1> -->
  </div>

  <div class="panel-content-container " >
    <h1 class="result-header">{{ data.date }}</h1>
    <div class="team-container flex">
      <div class="team-left flex">
        <div style="flex: 1;" />
        <div>
          <div class="flex">
            <div style="flex: 1;" />
            <div class="flex" style="align-items: center;">
              <el-image class="img" :class="{'win-team-border': data.teamA?.name == winningTeam}" :src="require('@/assets/teams/logos/' + (data.teamA?.abbrev || 'default') + '.png')" fit="cover" />
            </div>
            <div style="flex: 1;" />
          </div>
          <!-- <h1 class="team-text">{{ data.teamA?.name || null }}</h1> -->
        </div>
        <div style="flex: 1;" />
      </div>
      <h1 class="mid-text flex">
        vs
      </h1>
      <div class="team-right flex">
        <div style="flex: 1;" />
        <div>
          <div class="flex-column" style="align-items: center;">
            <el-image class="img" :class="{'win-team-border': data.teamB?.name == winningTeam}" :src="require('@/assets/teams/logos/' + (data.teamB?.abbrev || 'default') + '.png')" fit="cover" />
          </div>
          <!-- <h1 class="team-text">{{ data.teamB?.name || null }}</h1> -->
        </div>
        <div style="flex: 1;" />
      </div>
    </div>
    <div class="content-bot flex-column">
      <!-- <div style="flex: 1;" /> -->
      <div class="team-name-container flex">
        <div style="flex: 1;" />
        <h1 class="team-text" :class="{'win-team-text': data.teamA?.name == winningTeam}">{{ data.teamA?.name || null }}</h1>
        <div style="flex: 1;" />
        <div style="width: 21px;"></div>
        <div style="flex: 1;" />
        <h1 class="team-text" :class="{'win-team-text': data.teamB?.name == winningTeam}">{{ data.teamB?.name || null }}</h1>
        <div style="flex: 1;" />
      </div>
      <div class="result-container flex">
        <div style="flex: 1;" />
        <h1 class="result-score" :class="{'win-team-text': data.teamA?.name == winningTeam}">{{ data.teamAResult }}</h1>
        <div style="flex: 1;" />
        <hr style="width: 20px;border: 2px solid #161616;">
        <div style="flex: 1;" />
        <h1 class="result-score" :class="{'win-team-text': data.teamB?.name == winningTeam}">{{ data.teamBResult }}</h1>
        <div style="flex: 1;" />
      </div>
      <div class="result-container flex">
        <div style="flex: 1;" />
        <h1 class="result-status">Completed</h1>
        <div style="flex: 1;" />
      </div>
      <div class="result-container flex">
        <div style="flex: 1;" />
        <div class="result flex">
            <div style="flex: 1;" />
              <h1 class="team-text" style="width: 100%;">
                {{ winningTeam }}&nbsp;<span class="team-text" style="color: #5200FF;">{{ result }}</span>
              </h1>
              <div style="flex: 1;" />
            </div>
        <div style="flex: 1;" />
      </div>
      <!-- <div class="result-container flex">
        <div style="flex: 1;" />
        <h1 class="result-score">{{ data.teamAResult }}</h1>
        <div style="flex: 2;" />
        <div>
          <h1 class="result-status">Completed</h1>
          <div class="result flex">
            <div style="flex: 1;" />
            <h1 class="team-text">{{ winningTeam }}&nbsp;</h1>
            <h1 class="team-text" style="font-weight: 300;">{{ result }}</h1>
            <div style="flex: 1;" />
          </div>
        </div>
        <div style="flex: 2;" />
        <h1 class="result-score">{{ data.teamBResult }}</h1>
        <div style="flex: 1;" />
      </div> -->
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref, reactive } from 'vue'

import dashboardApi from '@/api/dashboard'

const emits = defineEmits(['resultLoadEvent']);

const winningTeam = ref('');
const result = ref();
const data:any = ref({
  date:null,
  teamAResult:null,
  teamBResult:null,
});


onBeforeMount( async () => {
  dashboardApi.latestResult().then(res => {
    if (res && res.code == 1) {
      data.value = res.response;
      let date = new Date(res.response.date);
      let time = res.response.date.split('T')[1];

      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      let daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      let dayOfWeek = daysOfWeek[date.getDay()];

      data.value.date = `${year}-${month}-${day} • ${dayOfWeek} • ${time}`;
      
      let teamAResult = res.response.teamAResult;
      let teamBResult = res.response.teamBResult;

      if (teamAResult > teamBResult) {
        winningTeam.value = res.response.teamA.name;
        result.value = "by " + (teamAResult - teamBResult) + " pts";
      }
      else if (teamAResult < teamBResult) {
        winningTeam.value = res.response.teamB.name;
        result.value = "by " + (teamBResult - teamAResult) + " pts";
      }
      else {
        winningTeam.value = 'DRAW';
      }

    }
    emits('resultLoadEvent');

  }).catch(err => {
    emits('resultLoadEvent');

  })
});

</script>

<style scoped>
.win-team-text {
  color: #5200FF !important;
}
.win-team-border {
  border: 3px solid #5200FF !important;
  height: 77px !important;
  width: 77px !important;
}
.content-bot {
  justify-content: space-between;
  height: calc(100% - 32px - 115px - 15px);
}
.mid-text {
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  align-items: center;
  margin: 0;
  /* position: relative;
  bottom:10px; */
}
.img {
  height: 80px;
  width: 80px;
  border-radius: 20px;
  background-color: #f5f5f5;
  /* margin-bottom: 10px; */
}
.result {
  margin-top: 10px;
}
.result-status {
  /* color: #EF4565; */
  font-size: 14px;
  line-height: 30px;
  width: 120px;
  font-weight: 500;
  margin: 0;
  text-align: center;
  border-radius: 20px;
  background-color: #EDEDED;
}
.result-container {
  /* height: 100px; */
  align-items: center;
}
.result-score {
  color: #161616;
  font-size: 36px;
  line-height: 36px;
  font-weight: 600;
  margin: 0;
  text-align: center;
  width: 80px;
}
.team-text {
  color: #161616;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  margin: 0;
  text-align: center;
  width: 80px;
}
.team-container {
  margin-top: 15px;
  height: 100px;
}
.team-name-container {
  margin-top: 5px;
  height: 50px;
}
.team-left {
  /* border-right: 1px solid #D9D9D9; */
  flex: 1;
  align-items: center;
}
.team-right {
  /* border-left: 1px solid #D9D9D9; */
  flex: 1;
  align-items: center;
}
.result-header {
  /* color: #5F6C7B; */
  font-size: 14px;
  line-height: 33px;
  font-weight: 500;
  margin: 0;
  text-align: center;
  background-color: #EDEDED;
}
.panel-content-container {
  padding: 15px 0 20px 0;
  height: calc(100% - 60px - 32px);
  /* justify-content: space-between; */
}
.panel-header-container {
  padding: 30px 30px 10px 30px;
  height: 20px;
  /* border-bottom: 2px solid #D9D9D9; */
  justify-content: space-between;
}
.panel-header {
  color: #161616;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  text-align: left;
  align-items: flex-end;
}
.panel-header-link {
  color: #5F6C7B;
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
  margin: 0;
  text-align: left;
  align-items: flex-end;
  cursor: pointer;
}
</style>