<template>
  <!-- max-height="calc(100vh - 100px - 30px - 60px - 50px - 30px)" -->
  <el-table 
  :row-class-name="tableRowClassName" 
  :data="data.value"
  :cell-style="{paddingLeft:'15px',fontWeight:'400'}" 
  :header-cell-style="{paddingLeft:'15px',paddingTop:'20px',paddingBottom:'20px', background:'#EDEDED', color:'#1D1D1D'}"
  >
    <el-table-column prop="rank" label="Rank">
      <template #default="scope">
        {{ addSuffix(scope.row.rank+1) }}
      </template>
    </el-table-column>
    <el-table-column prop="username" label="Tipper"/>
    <el-table-column prop="score" label="Score"/>
    <!-- <el-table-column prop="prize" label="Prize at the end of the season"/> -->
  </el-table>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { addSuffix } from '@/utils/number'

import scoreboardApi from '@/api/scoreboard'

const data = defineProps(['value']);

interface Result {
  id: string
  rank: string
  username: string
  score: string
}

const route = useRoute();
const router = useRouter();

const tableRowClassName = ({
  row,
  rowIndex,
}: {
  row: Result
  rowIndex: number
}) => {
  if (row.id == route.params.tip) {
    return 'success-row'
  }
  else {
    return 'striped'
  }
  return ''
}

const tableData: any = ref([
]);

onBeforeMount(() => {

});

</script>

<style>
.el-table .striped {
  /* --el-table-tr-bg-color: var(--el-color-success-light-9); */
  --el-table-tr-bg-color: #F5F5F5 !important;
  --el-table-row-hover-bg-color: #F5F5F5 !important;
  color: #161616;
}
/* .el-table .striped:hover {
  color: #161616;
  
} */
.el-table .success-row {
  --el-table-tr-bg-color: #5200FF;
  /* --el-table-tr-bg-color: rgba(61, 169, 252, 0.5); */
  --el-table-row-hover-bg-color: #F5F5F5 !important;
  font-weight: 500;
  color: #fffffe;
}
.el-table .success-row:hover {
  color: #161616;
}
</style>

<style scoped>
.operation-text {
  text-decoration: underline;
  color: #094067;
  font-size: 14px;
}
</style>