<template>
  <!-- max-height="calc(100vh - 100px - 30px - 60px - 50px - 30px)" -->
  <el-table 
  :data="data.value"
  :cell-style="{paddingLeft:'15px',color:'#161616',background:'#F5F5F5',fontWeight:'400'}" 
  :header-cell-style="{paddingLeft:'15px',paddingTop:'20px',paddingBottom:'20px', background:'#EDEDED', color:'#1D1D1D'}"
  >
    <el-table-column prop="cid" label="ID" width="80"/>
    <!-- <el-table-column prop="sport.sportName" label="Sport"/> -->
    <el-table-column prop="name" label="Name" width="200"/>
    <el-table-column prop="admin.username" label="Admin"/>
    <el-table-column prop="entryFee" label="Entry Fee">
      <template #default="scope">
        {{ formatMoney(scope.row.entryFee) }}
      </template>
    </el-table-column>
    <!-- <el-table-column prop="prizePool" label="Prize Pool"/> -->
    <el-table-column label="System Charge" min-width="100">
      <template #default="scope">
        {{ scope.row.systemFee + " %" }}
      </template>
    </el-table-column>
    <el-table-column prop="type" label="Type"/>
    <el-table-column label="Lock Date">
      <template #default="scope">
        {{ getDate(scope.row.startDate) }}
      </template>
    </el-table-column>
    <el-table-column fixed="right" label="Operations" width="200">
      <template #default="scope">
        <el-button class="operation-text" type="primary" color="#EDEDED" size="small"
        @click="detail(scope.row.admin.uid, scope.row.punterNumber, scope.row.prizePool)">Detail</el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-dialog v-model="dialogDetailVisible" title="Competition Details" width="800">
    <el-descriptions
      title="Competition Info"
      direction="vertical"
      :column="4"
      :border="true"
    >
      <el-descriptions-item label="Punter Amount" 
      width="300px"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ compInfo?.punterNumber }}</el-descriptions-item>
      
      <el-descriptions-item label="Current Prize Pool" :span="2"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ compInfo?.prizePool }}</el-descriptions-item>
    </el-descriptions>

    <el-descriptions
      title="Admin Info"
      direction="vertical"
      :column="4"
      :border="true"
      style="margin-top: 15px;"
    >
      <el-descriptions-item label="Admin ID" width="150px"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ adminInfo?.id }}</el-descriptions-item>

      <el-descriptions-item label="Name" width="300px"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ adminInfo?.username }}</el-descriptions-item>

      <el-descriptions-item label="Contact" :span="2"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ adminInfo?.phone }}</el-descriptions-item>

      <el-descriptions-item label="Mail Address"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ adminInfo?.email }}</el-descriptions-item>
    </el-descriptions>
  </el-dialog>

</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import userApi from '@/api/user'

const data = defineProps(['value']);

const dialogDetailVisible = ref(false);
const compInfo = ref({
  punterNumber: 0,
  prizePool: ''
});
const adminInfo = ref();

const detail = (id:number, punterNumber:number, prizePool:string) => {
  compInfo.value.punterNumber = punterNumber;
  compInfo.value.prizePool = prizePool;
  userApi.adminInfo({id:id}).then((res) => {
    if (res && res.code == 1) {
      adminInfo.value = res.response;
      console.log(adminInfo.value);
      dialogDetailVisible.value = true;
      // console.log(detailInfo.value);
    }
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
  });
}

const getDate = (date:string) => {
  return date.split('T')[0];
}
const formatMoney = (money:string) => {
  return money.replace('$', '$ ');
}
</script>

<style scoped>
.operation-text {
  /* text-decoration: underline; */
  /* color: #094067; */
  font-size: 12px;
  color: #1D1D1D;
  border-radius: 33px;
}
</style>