<template>
  <div class="dashboard-view">
    <el-row class="welcome-panel-container">
      <el-col>
        <!-- <WelcomePanel></WelcomePanel> -->
        <h1 class="welcome-text">
          Get tipping,
          <span style="font-weight:600;color: #5200FF;">{{ username }}</span>
          !
        </h1>
      </el-col>
      
    </el-row>

    <el-row :gutter="15" class="state-panel-container">
      <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6" v-for="(s, index) in states">
        <div
        >
          <StatePanel :value="s"></StatePanel>
        </div>
      </el-col>
    </el-row>

    <el-row :gutter="15" class="table-panel-container">
      <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <div class="table-panel" v-loading="tableLoad" element-loading-background="rgba(245, 245, 245, 0.3)">
          <DashboardTablePanel @tableLoadEvent="hanleTableload"></DashboardTablePanel>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" 
      v-loading="resultLoad" element-loading-background="rgba(245, 245, 245, 0.3)">
        <div class="table-panel"><DashboardResultPanel @resultLoadEvent="hanleResultload"></DashboardResultPanel></div>
      </el-col>
      <el-col :xs="24" :sm="24" :md="12" :lg="8" :xl="8" 
      v-loading="compLoad" element-loading-background="rgba(245, 245, 245, 0.3)">
        <div class="comp-panel"><UpcomingCompetitionPanel @compLoadEvent="hanleCompload"></UpcomingCompetitionPanel></div>
      </el-col>
    </el-row>

    <div style="height: 50px;"></div>
  </div>
</template> 

<script lang="ts" setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import WelcomePanel from '@/components/dashboard/WelcomePanel.vue'
import StatePanel from '@/components/dashboard/StatePanel.vue'
import DashboardTablePanel from '@/components/dashboard/DashboardTablePanel.vue'
import DashboardResultPanel from '@/components/dashboard/DashboardResultPanel.vue'
import UpcomingCompetitionPanel from '@/components/dashboard/UpcomingCompetitionPanel.vue'

import dashboardApi from '@/api/dashboard'
import paymentApi from '@/api/payment'
import userApi from '@/api/user'

const route = useRoute();
const router = useRouter();
const query = route.query;

const username = ref('');

const winLoad = ref(true);
const HisRankLoad = ref(true);
const ActRankLoad = ref(true);
const CorrectLoad = ref(true);

const tableLoad = ref(true);
const resultLoad = ref(true);
const compLoad = ref(true);

const states:any = reactive([
  {
    header:"Total Winning",
    icon:"TrophyBase",
    prize:"N/A",
    // change:"25%",
    // up:1,
    loading:winLoad
  },
  {
    header:"History Rank",
    icon:"GoldMedal",
    prize:"N/A",
    suffix:true,
    // change:"3",
    // up:1,
    loading:HisRankLoad
  },
  {
    header:"Current Rank",
    icon:"GoldMedal",
    prize:"N/A",
    suffix:true,
    // change:"2",
    // up:1,
    loading:ActRankLoad
  },
  {
    header:"Correct Rate",
    icon:"Star",
    prize:"N/A",
    // change:"5%",
    // up:-1,
    loading:CorrectLoad
  }
]);

const hanleTableload = () => {
  tableLoad.value = false;
}

const hanleResultload = () => {
  resultLoad.value = false;
}

const hanleCompload = () => {
  compLoad.value = false
}

onBeforeMount( async () => {
  if (query.success) {
    const cid = query.cid, cuid = query.cuid;

    sessionStorage.removeItem(`${cid}-session`);

    ElMessageBox.confirm(
      'Congratulations! You have joined the competition successfully!',
      'Success',
      {
        confirmButtonText: 'Tip now!',
        cancelButtonText: 'Check other features',
        type: 'success',
      }
    ).then(async () => {
      router.push(`tip/${cuid}`);
    })
    .catch((err) => {
      // ElMessage({
      //   type: 'warning',
      //   message: 'Pay link canceled',
      // });
    });

  }
  else if (query.cancel) {
    const cid = query.cid, cuid = query.cuid;

    ElMessageBox.confirm(
      'Oops! Seems like you canceled your payment! You can manage it in the Order Management section',
      'warning',
      {
        confirmButtonText: 'Continue paying',
        cancelButtonText: 'Canceled',
        type: 'warning',
      }
    ).then(async () => {
      let session = sessionStorage.getItem(`${cid}-session`);
      paymentApi.getSession({sessionId:session}).then(res => {
        if (res && res.code == 1) {
          window.location.href = res.response.sessionUrl;
        }
        // loading.value = false;
      }).catch(err => {
        // loading.value = false;
        ElMessage({
          type: 'error',
          message: err,
        });
      });
    })
    .catch((err) => {
      // ElMessage({
      //   type: 'warning',
      //   message: 'Pay link canceled',
      // });
    });

  }

  userApi.validate().then(res => {
    if (res && res.code == 1) {
      username.value = res.response.username;
      // loading.value=false;
    }
    else {
      // loading.value=false;
    }
  }).catch(err => {   
    // loading.value=false;                   
    // router.push('home');
  });


  // winLoad.value = true;
  dashboardApi.totalWining().then(res => {
    if (res && res.code == 1) {
      states[0].prize = res.response.totalWinning?.slice(0, -3);
      if (res.response.change != "0%") {
        if (res.response.change.includes('%')) {
          states[0].change = res.response.change;
        }
        else {
          states[0].change = res.response.change.slice(0, -3);
        }
        states[0].up = 1;
      }
      winLoad.value = false;
    }
  }).catch(err => {
    winLoad.value = false;
  });

  // HisRankLoad.value = true;
  dashboardApi.hisHighRank().then(res => {
    if (res && res.code == 1 && res.response.rank != "0th") {
      states[1].prize = res.response.rank;
      states[1].total = res.response.punters;

      let change = Math.abs(res.response.change);
      states[1].change = change != 0 ? change : null;
      if (res.response.change > 0) {
        states[1].up = 1;
      }
      else if (res.response.change < 0) {
        states[1].up = -1;
      }
    }
    HisRankLoad.value = false;
  }).catch(err => {
    HisRankLoad.value = false;
  });

  // ActRankLoad.value = true;
  dashboardApi.actHighRank().then(res => {
    if (res && res.code == 1 && res.response.rank != "0th") {
      states[2].prize = res.response.rank;
      states[2].total = res.response.punters;

      let change = Math.abs(res.response.change);
      states[2].change = change != 0 ? change : null;
      if (res.response.change > 0) {
        states[2].up = 1;
      }
      else if (res.response.change < 0) {
        states[2].up = -1;
      }
    }
    ActRankLoad.value = false;
  }).catch(err => {
    ActRankLoad.value = false;
  });

  // CorrectLoad.value = true;
  dashboardApi.correctRate().then(res => {
    if (res && res.code == 1 && res.response?.rate != -1) {
      states[3].prize = res.response.rate + "%";
      let change = Math.abs(res.response.change);
      states[3].change = (change != 0 ? change : null) + "%";
      if (res.response.change > 0) {
        states[3].up = 1;
      }
      else if (res.response.change < 0) {
        states[3].up = -1;
      }
    }
    CorrectLoad.value = false;
  }).catch(err => {
    CorrectLoad.value = false;
  });


});

</script>

<style>
.dashboard-view .el-loading-mask {
  border-radius: 20px;
}
</style>

<style scoped>
.dashboard-view {
  margin-left: 50px;
}
.welcome-text {
  margin: 0;
  font-size: 36px;
  line-height: 36px;
  text-align: left;
  font-weight: 500;
  user-select: none;
}
.welcome-panel-container {
  /* padding: 15px 30px 15px 15px; */
  /* height: 125px; */
  width: calc((100vw - 450px) / 4 * 3 + 60px);
  /* background-color: rgba(216, 238, 254, 0.5);
  border-radius: 5px; */
  margin-bottom: 50px;
  margin-top: 50px;
  /* box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1); */
}
.state-panel-container {
  /* height: 120px; */
  width: calc(100vw - 400px);
  margin-bottom: 15px;
}
.table-panel {
  /* height:calc(100vh - 100px - 120px * 2 - 30px * 3); */
  min-height: 450px;
  height:calc(100vh - 50px - 136px - 211px - 30px);
  /* width: calc(((100vw - 450px) / 4 * 3 + 60px - 30px) / 2); */
  background-color: #F5F5F5;
  border-radius: 20px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
}
.table-panel-container {
  /* height:calc(100vh - 100px - 120px * 2 - 30px * 3); */
  /* height: 340px; */
  width: calc(100vw - 400px);
  /* width: calc((100vw - 450px) / 4 * 3 + 60px + 30px); */
}
.comp-panel {
  min-height: 450px;
  height:calc(100vh - 50px - 136px - 211px - 30px);
  /* width: calc((100vw - 450px) / 4); */
  background-color: #F5F5F5;
  border-radius: 20px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
}
.comp-panel-container {
  /* height:calc(100vh - 100px - 120px * 2 - 30px * 3); */
  height: 340px;
  width: calc((100vw - 450px) / 4);
  /* margin-left: 30px; */
}
</style>