<template>
  <div class="box-background img-background flex">
    <div style="flex: 1;" />
    <div class="flex" v-if="login">
      <div style="flex: 1;" />
      <div class="login-box" v-loading="loading" element-loading-background="rgba(255, 255, 255, 0.3)">
        <el-row>
          <div style="flex: 1;" />
          <el-image class="thunder" :src="require('@/assets/img/thunder.svg')" fit="cover" />
          <div style="flex: 1;" />
        </el-row>
        <el-row class="header-background">
            <div style="flex: 1;" />
            <h1 class="login-header-text">LOGGED IN</h1>
            <div style="flex: 1;" />
        </el-row>
        <el-row class="login-main-background">
          <div style="width: 100%;">
            <h2 class="welcome-title">{{ `Hi ${username}! Welcome back!` }}</h2>
            <!-- <h2 class="welcome-content-text">Let's go tipping now!</h2>
            <h2 class="welcome-content-text">Move to dashboard to use our full tipping service</h2> -->
            <div class="flex" style="margin-top: 40px; justify-content: space-between;">
              <el-button type="primary" class="dashboard-btn" color="#5200FF" @click="router.push('/dashboard')" >DASHBOARD</el-button>
              <!-- color="#90B4CE" -->
              <el-button 
              type="primary" 
              class="dashboard-btn" 
              color="#E43700" 
              @click="logout"
              >LOGOUT</el-button>
            </div>
          </div>
        </el-row>
      </div>
      <div style="flex: 1;" />
    </div>
    <div class="flex" v-else>
      <div style="flex: 1;" />
      <div class="box" v-loading="blinkLoading" element-loading-background="rgba(255, 255, 255, 0.3)">
        <el-row>
          <div style="flex: 1;" />
          <el-image class="thunder" :src="require('@/assets/img/thunder.svg')" fit="cover" />
          <div style="flex: 1;" />
        </el-row>
        <el-row class="header-background">
            <div style="flex: 1;" />
            <h1 class="login-header-text">Log in and start tipping</h1>
            <div style="flex: 1;" />
        </el-row>
        <el-row class="main-background">
          <LoginForm @loginEvent="handleLogin" @startEvent="handleStart" @endEvent="handleEnd" @registerEvent="handleRegisterEvent"></LoginForm>
        </el-row>
      </div>
      <div style="flex: 1;" />
    </div>
    <div style="flex: 1;" />
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import LoginForm from '@/components/home/LoginForm.vue'
import userApi from '@/api/user'

const router = useRouter();

const emits = defineEmits(['registerEvent']);

const login = ref(false);
const loading = ref(false);
const blinkLoading = ref(true);
const username = ref('');

const logout = () => {
  loading.value = true;

  userApi.logout().then(res => {
    if (res && res.code == 1) {
      loading.value = false;
      login.value = false;
    }
  }).catch(err => {
    loading.value = false;
  })
}

const handleLogin = (user:any) => {
  login.value = true;
  username.value = user.username;
}

const handleStart = (user:any) => {
  blinkLoading.value = true;
}

const handleEnd = (user:any) => {
  blinkLoading.value = false;
}

const handleRegisterEvent = (value:boolean) => {
  // console.log('Received custom event from child:', value);
  emits('registerEvent', value);
};
// store.commit('setUser', res.data.response);

onBeforeMount( async () => {
  // setTimeout(() => {}, 300);
  userApi.validate().then(res => {
    if (res && res.code == 1) {
      login.value = true;
      blinkLoading.value = false;
      username.value = res.response.username;
    }
    else {
      login.value = false;
      blinkLoading.value = false;
    }
  }).catch(err => {                      
    login.value = false;
    blinkLoading.value = false;
    // router.push('home');
  });
});


</script>

<style scoped>
.thunder {
  width: 90px;
  height: 90px;
  margin: 40px 0;
}
.login-header-text {
  color:#1E1E1E;
  font-size: 22px;
  line-height: 22px;
  font-weight: 500;
  margin: 0;
}
.dashboard-btn {
  border-radius: 20px;
  width: 150px;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
  color: #fffffe;
}
.welcome-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 18px;
  margin: 0 0 30px 0;
  text-align: center;
  color: #1E1E1E;
}
.welcome-content-text {
  font-size: 16px;
  font-weight: 300;
  line-height: 18px;
  margin: 0 0 5px 0;
  text-align: center;
}
.login-main-background {
  padding: 50px 63px;
}
.main-background {
  padding: 50px 63px;
  height: 370px;
}
.header-background {
  /* height: 80px; */
  /* border-bottom: 2px solid #D9D9D9; */
  /* box-sizing: border-box; */
}
.box {
  background-color: rgba(245, 245, 245, 0.9);
  /* border-top: 5px solid #094067; */
  border-radius: 20px;
  height: 600px;
  width: 450px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
}
.login-box {
  background-color: rgba(245, 245, 245, 0.9);
  /* border-top: 5px solid #094067; */
  border-radius: 20px;
  height: 400px;
  width: 450px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
}
.box-background {
  padding: 0;
  margin: 0;
  background-color: #fffffe;
  /* border-radius: 0 5px 5px 0; */
  height: 100vh;
  min-height: 650px;
  flex-direction: column;
}
.img-background {
  background-image: url('@/assets/background.jpg');
  background-size: cover !important;
  background-position: center;
}
</style>