<template>
  <!-- max-height="calc(100vh - 100px - 30px - 60px - 50px - 30px - 32px - 30px)"  -->
  <el-table 
  :data="data.value" 
  v-loading="loading"
  :cell-style="{paddingLeft:'15px',color:'#161616',background:'#F5F5F5',fontWeight:'400'}" 
  :header-cell-style="{paddingLeft:'15px',paddingTop:'20px',paddingBottom:'20px', background:'#EDEDED', color:'#1D1D1D'}"
  >
    <el-table-column prop="oid" label="ID"/>
    <el-table-column prop="paymentStatus" label="Status"/>
    <!-- <el-table-column prop="sport.sportName" label="Sport"/>
    <el-table-column prop="admin.username" label="Admin"/>
    <el-table-column prop="entryFee" label="Entry Fee">
      <template #default="scope">
        {{ formatMoney(scope.row.entryFee) }}
      </template>
    </el-table-column>
    <el-table-column label="System Charge">
      <template #default="scope">
        {{ scope.row.systemFee + " %" }}
      </template>
    </el-table-column> -->
    <el-table-column label="Creation Time">
      <template #default="scope">
        {{ getDateTime(scope.row.timestamp) }}
      </template>
    </el-table-column>
    <el-table-column label="Expire In">
      <template #default="scope">
        <el-countdown
          title=""
          format="HH:mm:ss"
          :value="new Date(scope.row.expiration)"
          value-style="color:#EF4565;font-size:14px;font-weight:500"
        />
      </template>
    </el-table-column>
    <el-table-column fixed="right" label="Operations" width="300">
      <template #default="scope">
        <el-button class="operation-text" type="primary" color="#EDEDED" size="small" @click="pay(scope.row.sessionId)" :disabled="payLoading">Pay</el-button>
        <el-button class="operation-text" type="primary" color="#EDEDED" size="small" @click="cancel(scope.row.oid)">Cancel</el-button>
        <el-button class="operation-text" type="primary" color="#EDEDED" size="small" @click="detail(scope.row.cid, scope.row.cuid)">Detail</el-button>
      </template>
    </el-table-column>
  </el-table>

  <el-dialog v-model="dialogDetailVisible" title="Order Details" width="800">
    <el-descriptions
      title="Competition Info"
      direction="vertical"
      :column="4"
      :border="true"
    >
      <el-descriptions-item label="Comp ID" 
      width="150px" 
      label-class-name="my-label" 
      class-name="my-content"
      >{{ detailInfo?.cid }}</el-descriptions-item>

      <el-descriptions-item label="Type" 
      width="300px"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ detailInfo?.type }}</el-descriptions-item>
      
      <el-descriptions-item label="Entry Fee" :span="2"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ detailInfo?.entryFee }}</el-descriptions-item>
    </el-descriptions>

    <el-descriptions
      title="Admin Info"
      direction="vertical"
      :column="4"
      :border="true"
      style="margin-top: 15px;"
    >
      <el-descriptions-item label="Admin ID" width="150px"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ detailInfo?.admin.id }}</el-descriptions-item>

      <el-descriptions-item label="Name" width="300px"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ detailInfo?.admin.username }}</el-descriptions-item>

      <el-descriptions-item label="Contact" :span="2"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ detailInfo?.admin.phone }}</el-descriptions-item>

      <el-descriptions-item label="Mail Address"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ detailInfo?.admin.email }}</el-descriptions-item>
    </el-descriptions>

    <el-descriptions
      title="Tipping Info"
      direction="vertical"
      :column="4"
      :border="true"
      style="margin-top: 15px;"
    >
    
      <el-descriptions-item label="ID" width="150px" :span="1"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ tippingInfo?.id }}</el-descriptions-item>

      <el-descriptions-item label="Age" width="150px" :span="1"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ tippingInfo?.age }}</el-descriptions-item>

      <el-descriptions-item label="Gender" width="300px" :span="2"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ tippingInfo?.gender }}</el-descriptions-item>

      <el-descriptions-item label="Contact" width="300px" :span="2"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ tippingInfo?.phone }}</el-descriptions-item>

      <el-descriptions-item label="Mail" width="300px" :span="2"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ tippingInfo?.email }}</el-descriptions-item>

      <el-descriptions-item label="Address" :span="4"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ tippingInfo?.address }}</el-descriptions-item>

      <el-descriptions-item label="City" width="150px" :span="1"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ tippingInfo?.city }}</el-descriptions-item>

      <el-descriptions-item label="State" width="150px" :span="1"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ tippingInfo?.state }}</el-descriptions-item>

      <el-descriptions-item label="Postcode" width="300px" :span="2"
      label-class-name="my-label" 
      class-name="my-content"
      >{{ tippingInfo?.postcode }}</el-descriptions-item>

    </el-descriptions>
  </el-dialog>

</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import paymentApi from '@/api/payment'
import orderApi from '@/api/order'
import competitionJoinApi from '@/api/competitionJoin'
import tippingInfoApi from '@/api/tippingInfo'

const route = useRoute();
const router = useRouter();

const data = defineProps(['value']);
const emits = defineEmits(['reloadEvent']);

const payLoading = ref(false);
const loading = ref(false);

const dialogDetailVisible = ref(false);
const detailInfo = ref();
const tippingInfo = ref();

const detail = (cid:number, cuid:number) => {
  competitionJoinApi.competitionConfirm({cid:cid}).then((res) => {
    if (res && res.code == 1) {
      detailInfo.value = res.response;
      dialogDetailVisible.value = true;
      // console.log(detailInfo.value);
    }
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
  });

  tippingInfoApi.info({id:cuid}).then((res) => {
    if (res && res.code == 1) {
      tippingInfo.value = res.response;
      console.log(res.response);
      dialogDetailVisible.value = true;
      // console.log(detailInfo.value);
    }
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
  });
}

const getDateTime = (date:string) => {
  return date.replace('T', ' • ').slice(0, -3);;
}
const formatMoney = (money:string) => {
  return money.replace('$', '$ ');
}

const del = (id:number) => {
  orderApi.delete({id:id}).then(res => {
    if (res && res.code == 1) {
      emits('reloadEvent');
      ElMessage({
        type: 'success',
        message: 'Cancel successfully',
      });
    }
    // payLoading.value = false;
  }).catch(err => {
    // payLoading.value = false;
    emits('reloadEvent');
    ElMessage({
      type: 'error',
      message: err,
    });
  });
}

const cancel = (id:number) => {
  ElMessageBox.confirm(
    'This will cancel your order. If you paid already, do not cancel your order!',
    'Warning',
    {
      confirmButtonText: 'yes',
      cancelButtonText: 'no',
      type: 'warning',
    }
  ).then(() => {
    del(id);
  })
  .catch(() => {
    ElMessage({
      type: 'warning',
      message: 'operation canceled',
    });
  });

}

const pay = (sessionId:string) => {
  payLoading.value = true;
  paymentApi.getSession({sessionId:sessionId}).then(res => {
    if (res && res.code == 1) {
      if (res.response.sessionUrl != null) {
        window.open(res.response.sessionUrl);
      }
      else {
        ElMessage({
          type: 'error',
          message: 'Payment session expired',
        });
      }
    }
    payLoading.value = false;
  }).catch(err => {
    payLoading.value = false;
    ElMessage({
      type: 'error',
      message: 'Payment session expired',
    });
  });

}

</script>

<style scoped>
.operation-text {
  /* text-decoration: underline; */
  /* color: #094067; */
  font-size: 12px;
  color: #1D1D1D;
  border-radius: 33px;
}
</style>