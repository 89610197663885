<template>
  <div class="form-container flex-column">
    <div class="form-content-container" v-loading="loading" element-loading-background="rgba(245, 245, 245, 0.3)">
      <el-form 
        label-position="left"
        :model="form"
        :rules="rules"
        ref="registerFormRef"
      >
        <h1 class="form-header flex" style="margin: 0 0 10px 5px; ">CONTACT</h1>
        <el-row :gutter="15" class="input-group">
          <el-col :span="8">
            <el-form-item label="" prop="mobile">
              <el-input class="form-input" v-model="form.mobile" placeholder="Phone number"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="" prop="email">
              <el-input class="form-input" v-model="form.email" placeholder="Email"/>
            </el-form-item>
          </el-col>
        </el-row>
        <h1 class="form-header flex" style="margin: 0 0 10px 5px; ">GENERAL INFO</h1>

        <el-row :gutter="15" class="input-group">
          <el-col :span="8">
            <el-form-item label=""  prop="username">
              <el-input class="form-input" v-model="form.username" placeholder="Username"/>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="8">
            <el-form-item label="" prop="age">
              <el-input class="form-input" v-model="form.age" placeholder="Age"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="" prop="gender">
              <el-input class="form-input" v-model="form.gender" placeholder="Gender"/>
            </el-form-item>
          </el-col> -->
        </el-row>

        <h1 class="form-header flex" style="margin: 0 0 10px 5px; ">ADDRESS</h1>
        <el-row :gutter="15" class="input-group">
          <el-col :span="8">
            <el-form-item label="" prop="address">
              <el-input class="form-input" v-model="form.address" placeholder="Address"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="" prop="city">
              <el-input class="form-input" v-model="form.city" placeholder="City"/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-row :gutter="5">
              <el-col :span="12">
                <el-form-item label="" prop="state">
                  <el-input class="form-input" v-model="form.state" placeholder="State"/>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="" prop="postcode">
                  <el-input class="form-input" v-model="form.postcode" placeholder="Postcode"/>
                </el-form-item>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="form-footer-container flex">
      <!-- <el-button type="primary" class="submit-btn" color="#3DA9FC" >PREV</el-button> -->
      <div style="flex:1" />
      <el-button class="submit-btn" color="#5200FF" @click="submit(registerFormRef)" :disabled="loading">Next</el-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'
import type { FormInstance, FormRules } from 'element-plus'

import registerApi from '@/api/register'
import tippingApi from '@/api/tipping'
import userApi from '@/api/user'

const route = useRoute();
const router = useRouter();

const registerFormRef = ref<FormInstance>();

const competitionId = route.params.competition;
const loading = ref(false);

const emits = defineEmits(['joinEvent']);

const data = defineProps(['value']);

const form = ref({
  mobile: '',
  email: '',
  username:'',
  gender: '',
  age: '',
  address: '',
  city: '',
  state: '',
  postcode: '',
});
form.value = { ...form.value, ...data.value };

const validateNotNull = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error('Cannot leave this as blank'));
  }
  else {
    callback();
  }
}
const validateEmail = (rule: any, value: any, callback: any) => {
  if (!value) {
    callback(new Error('Please input the email'));
  }
  else {
    callback()
  }
}
const validateMobile = (rule: any, value: any, callback: any) => {
  if (!value) {
    callback(new Error('Please input the mobile'));
  }
  else {
    callback()
  }
}
const validateAge = (rule: any, value: any, callback: any) => {
  if (!value) {
    callback(new Error('Please input the age'));
  }
  else {
    callback()
  }
}

const rules = reactive<FormRules<typeof form>>({
  username: [{ validator: validateNotNull, trigger: 'blur' }],
  email: [{ validator: validateNotNull, trigger: 'blur' }],
  mobile: [{ validator: validateNotNull, trigger: 'blur' }],
  age: [{ validator: validateNotNull, trigger: 'blur' }],
  gender: [{ validator: validateNotNull, trigger: 'blur' }],
  address: [{ validator: validateNotNull, trigger: 'blur' }],
  city: [{ validator: validateNotNull, trigger: 'blur' }],
  state: [{ validator: validateNotNull, trigger: 'blur' }],
  postcode: [{ validator: validateNotNull, trigger: 'blur' }],
})

const submit = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  formEl.validate(valid => {
    if (valid) {
      loading.value = true;
      tippingApi.usernameCheck({username:form.value.username, cid:competitionId}).then(res => {
        if (res && res.code == 1) {
          registerApi.sendEmailCode({email:form.value.email}).catch(err => {
            console.log(err);
          });
          emits('joinEvent', 1, form.value);
        }
        loading.value = false;
      }).catch(err => {
        ElMessage({
          type: 'error',
          message: err,
        });
        loading.value = false;
      });
    }
    else {

    }
  });
}

onBeforeMount(() => {
  userApi.detailInfo().then(res => {
    if (res && res.code == 1) {
      form.value = { ...form.value, ...res.response };
    }
  })
});

</script>

<style scoped>
.form-container {
  justify-content: space-between;
  height: calc(100vh - 156px - 50px - 30px - 115px - 30px - 92px - 20px);
  min-height: calc(650px - 30px - 115px - 30px - 92px - 20px);
}
.el-form-item {
  margin-bottom: 0;
}
.form-footer-container {
  padding: 0 30px 10px 30px;
  /* border-top: 2px solid #D9D9D9; */
  justify-content: space-between;
}
.input-group {
  /* box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.05); */
  /* background-color: rgba(144, 180, 206, 0.15); */
  margin-bottom: 30px;
  /* padding: 10px 15px; */
}
.join-form-row {
  justify-content: space-between;
}
.form-input {
  height: 40px;
  /* width: calc(((100vw - 450px) / 4 * 3 + 60px - 30px * 3 - 100px * 2 - 12px * 2) / 2); */
}
.form-content-container {
  padding: 15px 30px 0 30px;
}
.form-header {
  color: #161616;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin: 0;
  text-align: left;
  align-items: flex-end;
}
.submit-btn {
  width: 180px;
  height: 50px;
  font-size: 18PX;
  font-weight: 500;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  /* color: #F5F5F5; */
  border-radius: 12px;
}
</style>

