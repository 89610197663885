<template>
  <div class="form-container flex-column">
    <div class="form-content-container" v-loading="loading" element-loading-background="rgba(245, 245, 245, 0.3)">
      <h1 class="form-header flex" style="margin: 0 0 30px 5px;">PAYMENT LINK</h1>
      <el-row class="form-content flex" style="margin: 0 0 10px 5px; font-weight: 300;">
        <el-col :xs="24" :sm="12" :md="12" :lg="4" :xl="4">
          <h1 class="form-header flex" style="font-weight: 300;">
            <!-- click this -->
            <div class="form-text" @click="pay">Click this</div>
          </h1>
        </el-col>
      </el-row>
    </div>
    <div class="form-footer-container flex">
      <!-- <el-button type="primary" class="submit-btn" color="#3DA9FC" @click="emits('paymentEvent', -1);" :disabled="loading">PREV</el-button> -->
      <el-button type="primary" class="submit-btn" color="#5200FF" @click="submit" :disabled="loading">CONFIRM PAYMENT</el-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import competitionJoinApi from '@/api/competitionJoin'
import tippingApi from '@/api/tipping'
import paymentApi from '@/api/payment'


const route = useRoute();
const router = useRouter();
const competitionId = route.params.competition;

const sessionUrl = ref('');

const emits = defineEmits(['paymentEvent']);
const data = ref();

const form = defineProps(['value']);
const loading = ref(false);


const submit = () => {
  loading.value = true;
  paymentApi.paymentConfirm({cuid:form.value.cuid}).then(res => {

    loading.value = false;

    if (res && res.code == 1) {

      ElMessageBox.confirm(
        'Congratulations! You have joined the competition successfully!',
        'Success',
        {
          confirmButtonText: 'Tip now!',
          cancelButtonText: 'Check other features',
          type: 'success',
        }
      ).then(async () => {
        router.push(`/tip/${form.value.cuid}`);
      })
      .catch((err) => {
        router.push(`/dashboard`);
        // location.reload();
        // ElMessage({
        //   type: 'warning',
        //   message: 'Pay link canceled',
        // });
      });

    }

    else if (res && res.code == 201) {

      ElMessageBox.confirm(
        'We haven\'t received your payment yet. This might due to the Internet issue, don\'t worry about that!',
        'warning',
        {
          confirmButtonText: 'Continue paying',
          cancelButtonText: 'Waiting',
          type: 'warning',
        }
      ).then(async () => {
        window.open(form.value.sessionUrl);
      })
      .catch((err) => {
        // ElMessage({
        //   type: 'warning',
        //   message: 'Pay link canceled',
        // });
      });

    }

  }).catch(err => {

    loading.value = false;
    router.push('/dashboard');
    ElMessage({
      type: 'warning',
      message: 'Payment session expired',
    });

  });

}

const pay = () => {
  // console.log(form.value.sessionUrl);
  window.open(form.value.sessionUrl);
}

onBeforeMount(async () => {
  // let session = sessionStorage.getItem(`${competitionId}-session`);
  // paymentApi.getSession({sessionId:session}).then(res => {
  //   if (res && res.code == 1) {
  //     sessionUrl.value = res.response.sessionUrl;
  //   }
  //   loading.value = false;
  // }).catch(err => {
  //   loading.value = false;
  //   console.log(err);
  // });

  // sessionUrl.value = form.value.sessionUrl;
  // loading.value = false;
  
  // competitionJoinApi.competitionConfirm({cid:competitionId}).then(res => {
  //   if (res && res.code == 1) {
  //     data.value = res.response;
  //   }
  //   loading.value = false;
  //   // console.log(res);
  // }).catch(err => {
  //   loading.value = false;
  //   // console.log(err);
  // });

});

</script>

<style scoped>
.form-container {
  justify-content: space-between;
  height: calc(100vh - 156px - 50px - 30px - 115px - 30px - 92px - 20px);
  min-height: calc(650px - 30px - 115px - 30px - 92px - 20px);
}
.form-text {
  color: #161616;
  font-size: 16px;
  line-height: 16px;
  font-weight: 400;
  margin: 0;
  text-align: left;
  align-items: flex-end;
  text-decoration: underline;
  cursor: pointer;
}
.form-content {
  /* justify-content: space-between; */
  width: 100%;
}
.form-header {
  color: #161616;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin: 0;
  text-align: left;
  align-items: flex-end;
}

.form-footer-container {
  padding: 0px 30px 10px 30px;
  /* border-top: 2px solid #D9D9D9; */
  justify-content: space-between;
}
.form-content-container {
  padding: 30px 30px;
}
.submit-btn {
  width: 260px;
  height: 50px;
  font-size: 18PX;
  font-weight: 500;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  color: #F5F5F5;
  border-radius: 12px;
}
</style>