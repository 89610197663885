import { get, post } from '@/utils/request'

export default {
  activeNotNullCompetitions: (query: any) => get(`/competition/active-notnull`, query),
  activeNotNullCompetitionsOrder: (query: any) => get(`/competition/active-notnull-order`, query),
  activeCompetitions: (query: any) => get(`/competition/active`, query),
  closedCompetitions: (query: any) => get(`/competition/closed`, query),
  activeCount: () => get(`/competition/active-count`, null),
  closedCount: () => get(`/competition/closed-count`, null),
  competitionByTipID: (query: any) => get(`/competition/competition-by-tipping`, query),
  
  competitionRound: (query: any) => get(`/competition-round/rounds`, query),

  competitionDetail: (query: any) => get(`/competition/competition-detail`, query),
}