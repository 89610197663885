export const isWap = () => {
  var userAgentInfo = navigator.userAgent;
  var Agents = [
    'Android',
    'iPhone',
    'SymbianOS',
    'Windows Phone',
    'iPad',
    'iPod'
  ];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }

  if (flag) {
    let width =
      document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 840) {
      flag = false;
    }
  }
  return !flag;
}
  
  // /** 当前url是否对应的wap页面 */
  // export const isWapPage = () => {
  //   const routerNames:any = []
  //   const getAllRouterNames = (array:any) => {
  //     array.forEach((element:any) => {
  //       routerNames.push(element.name)
  //       if (element.children?.length) {
  //         getAllRouterNames(element.children)
  //       }
  //     })
  //   }
  //   getAllRouterNames(routers)
  
  //   const path = window.location.href.split('#')[1]
  //   const curRoute = router.resolve(path)
  
  //   if (
  //     isWap() &&
  //     ((curRoute.name.indexOf('-wap') < 0 &&
  //       routerNames.includes(curRoute.name + '-wap')) ||
  //       curRoute.name.indexOf('-wap') >= 0)
  //   ) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }