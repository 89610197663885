<template>
  <div class="state-container">
    <div class="state-top-container">
      <div class="state-change-container flex">
        <div v-if="data.value.up == 1" class="state-change state-up flex" v-loading="data.value.loading" element-loading-background="rgba(245, 245, 245, 0.3)">
          <el-icon style="margin-right: 5px;"><TopRight /></el-icon>
          UP {{ data.value.change }}
        </div>
        <div v-else-if="data.value.up == -1" class="state-change state-down flex" v-loading="data.value.loading" element-loading-background="rgba(245, 245, 245, 0.3)">
          <el-icon style="margin-right: 5px;"><BottomRight /></el-icon>
          DOWN {{ data.value.change }}
        </div>
      </div>
    </div>
    <div class="state-bot-container flex-column" v-loading="data.value.loading" element-loading-background="rgba(245, 245, 245, 0.3)">
      <h1 class="state-header flex">
        <el-icon style="margin-right: 10px; font-size: 40px;">
          <component :is="data.value.icon" />
        </el-icon>
        {{ data.value.header}}
      </h1>
      <div class="state-bot flex">
        <h2 class="state flex">
          <!-- <el-icon style="margin-right: 10px;"><component :is="data.value.icon" /></el-icon> -->
          {{ data.value.prize }}
          <div class="suffix-text"
          v-if="data.value.suffix && data.value.prize != 'N/A' && getSuffix(data.value.prize) != ''">
            {{ getSuffix(data.value.prize) }}
          </div>
          <div v-if="data.value.total != null">
            &nbsp;/ {{ data.value.total }}
          </div>
        </h2>
        <div style="flex: 1;" />
        <!-- <h3 class="state-change flex">
          <el-icon v-if="data.value.up == 1"><Top /></el-icon>
          <el-icon v-else-if="data.value.up == -1"><Bottom /></el-icon>
          {{ data.value.change }}
        </h3> -->
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import { getSuffix } from '@/utils/number'

const data = defineProps(['value']);
const loading = ref(true);
</script>

<style scoped>
.state-bot {
  margin: 30px 0 15px 0;
}
.state-top-container {
  position: relative;
  top: 15px;
  z-index: 999;
}
.state-bot-container {
  /* height: 100%; */
  justify-content: space-between;

  background-color: #F5F5F5;
  border-radius: 20px;
  padding: 15px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
.state-header {
  /* color: #5F6C7B; */
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  margin: 30px 0 0 0;
  text-align: left;
  width: 100px;
}
.state {
  /* height: 200px; */
  font-size: 36px;
  font-weight: 500;
  line-height: 36px;
  /* height: 40px; */
  margin: 0 0 0 8px;
  /* display: inline-block; */
  /* align-items: flex-end; */
  text-align: left;
}
.suffix-text {
  font-size: 18px;
  line-height: 18px;
}
.state-change-container {
  height: 30px;
}
.state-up {
  background-color: #5200FF;
}
.state-down {
  background-color: #E43700;
}
.state-change {
  min-width: 60px;
  padding: 0 15px;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  align-items: center;
  border-radius: 20px;
  margin-left: 25px;
}
.state-container {
  user-select: none;
}
/* .state-change {
  font-size: 14px;
  font-weight: 300;
  line-height: 14px;
  margin: 0 0 0 0;
  align-items: flex-end;
  text-align: left;
  display: inline-block;
} */
</style>