<template>
  <div class="panel-header-container flex">
    <h1 class="panel-header flex">
      Credit Ladder
    </h1>
    <h1 class="panel-header-link flex" @click="router.push('/current-tipping')">
      <!-- check complete ladder
      <el-icon style="font-size: 10px;"><Right /></el-icon> -->
      <el-icon><FullScreen /></el-icon>
    </h1>
  </div>
  <div class="panel-content-container" >
    <el-table 
    height="100%"
    :data="tableData" 
    :cell-style="{padding: '10px 0 0 15px',color:'#161616',background:'#F5F5F5'}" 
    :header-cell-style="{padding: '5px 0 5px 15px', background:'#EDEDED', color:'#1D1D1D'}">
      <el-table-column prop="username" label="Nickname" width="180px"/>
      <el-table-column prop="score" label="Score"/>
      <el-table-column prop="rank" label="Rank" />
    </el-table>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import dashboardApi from '@/api/dashboard'

const emits = defineEmits(['tableLoadEvent']);

const route = useRoute();
const router = useRouter();

const tableData = ref();

onBeforeMount( async () => {
  dashboardApi.actRank().then(res => {
    if (res && res.code == 1) {
      tableData.value = res.response;
    }
    emits('tableLoadEvent');
  }).catch(err => {
    emits('tableLoadEvent');
  })
});
</script>

<style>
.panel-content-container .el-table {
  background-color: #F5F5F5;
}
</style>

<style scoped>
.panel-content-container {
  padding: 15px 0 7.5px 0;
  height: calc(100% - 60px - 32px);
}
.panel-header-container {
  padding: 30px 30px 10px 30px;
  height: 20px;
  /* border-bottom: 2px solid #D9D9D9; */
  justify-content: space-between;
}
.panel-header {
  color: #161616;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  text-align: left;
  align-items: flex-end;
}
.panel-header-link {
  /* color: #5F6C7B; */
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  text-align: left;
  align-items: flex-end;
  cursor: pointer;
}
</style>