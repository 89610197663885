<template>
  <div class="login-form-container" >
    <el-form 
      label-position="left"
      :model="form"
      class="login-form"
      ref="registerFormRef"
      :rules="rules"
    >
      <el-form-item label="" prop="username">
        <el-input v-model="form.username" placeholder="Your email address"/>
      </el-form-item>
      <el-form-item label="" prop="password">
        <el-input v-model="form.password" placeholder="Your password" show-password/>
      </el-form-item>
      <el-row style="margin-top: 25px;">
        <div style="flex: 1;" />
        <!-- <el-switch v-model="form.remember" style="--el-switch-on-color: #3DA9FC;" /> -->
        <h5 class="remember-text">Remember my details</h5>
        <el-checkbox v-model="form.remember" style="margin-left: 5px;" ></el-checkbox>
        <div style="flex: 1;" />
      </el-row>
      <el-row style="margin-top: 25px;">
        <el-button type="primary" class="login-btn" color="#5200FF" @click="onSubmit(registerFormRef)">LOG IN</el-button>
      </el-row>
      <el-row style="margin-top: 20px;">
        <div style="flex: 1;" />
        <!-- <el-switch v-model="form.remember" style="--el-switch-on-color: #3DA9FC;" /> -->
        <h5 class="remember-text">Don’t have an account?</h5>
        <h5 class="signup-text" @click="emits('registerEvent', true)">Sign up</h5>
        <div style="flex: 1;" />
      </el-row>
        
    </el-form>
  </div>
</template>

<script lang="ts" setup>
import { ref, reactive } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import loginApi from '@/api/login'

const emits = defineEmits(['loginEvent','startEvent','endEvent','registerEvent']);

const form = reactive({
  username: '',
  password: '',
  remember: true
});

const registerFormRef = ref<FormInstance>();

const validateEmail= (rule: any, value: any, callback: any) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!value) {
    callback(new Error('Please input the email'));
  }
  else if (!emailRegex.test(value)) {
    callback(new Error('Please use correct email format'));
  }
  else {
    callback()
  }
}

const validatePassword = (rule: any, value: any, callback: any) => {
  if (!value) {
    callback(new Error('Please input the password'));
  }
  else {
    callback()
  }
}

const rules = reactive<FormRules<typeof form>>({
  username: [{ validator: validateEmail, trigger: 'blur' }],
  password: [{ validator: validatePassword, trigger: 'blur' }],
});

const login = () => {
  // loading.value = true;
  emits('startEvent');

  loginApi.login(form).then(res => {
    if (res && res.code == 1) {
      emits('loginEvent', {username: res.response.username});
    }
    else if (res.code == 402) {
      ElMessage({
        type: 'error',
        message: res.message,
      });
    }
    // loading.value = false;
    emits('endEvent');
    // console.log(res);
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
    // loading.value = false;
    emits('endEvent');
    // console.log(err);
  });
}

const onSubmit = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  formEl.validate(valid => {
    if (valid) {
      login();
    }
  });
}
</script>

<style>
.login-form-container .el-checkbox__inner {
  width: 20px;
  height: 20px;
}

.login-form-container .el-checkbox__inner::after {
  border: 3px solid #fff;
  border-left: 0;
  border-top: 0;
  left: 7px;
  top: 4px;
}
 
.login-form-container .el-checkbox__input.is-checked .el-checkbox__inner::after {
  transform: rotate(50deg) scaleY(1.3);

}

.login-form-container .is-checked .el-checkbox__inner {
  background-color: #5200FF;
  /* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25); */
  border: 0;
}
.login-form-container .el-checkbox__inner {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
}
.login-form-container .el-checkbox__inner:hover {
  /* background-color: #5200FF; */
  border-color: #5200FF;
}

/* .login-form-container .el-form-item__label {
  font-size: 20px;
  font-weight: 500;
  color: #094067;
} */
.login-form-container .el-input__wrapper {
  background-color: transparent;
  border-radius: 20px;
  border: 0.5px solid #2E2E2E;
}
.login-form-container .el-input__wrapper:hover {
  border: 0.5px solid #5200FF;
}
.login-form-container .el-input__wrapper.is-focus {
  border: 0.5px solid #5200FF;
}
.login-form-container .el-input {
  --el-input-focus-border-color: #5200FF !important;
}
.login-form-container .el-input__inner {
  background-color: transparent;
}

.login-form-container input:-webkit-autofill,
.login-form-container input:-webkit-autofill:hover,
.login-form-container input:-webkit-autofill:focus {
  border: 1px solid #dcdee2;
  -webkit-text-fill-color: #17233d;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

</style>

<style scoped>
.el-input {
  height: 50px;
}
.signup-text {
  margin: 0 0 0 5px;
  line-height: 32px;
  font-weight: 700;
  font-size: 14px;
  color: #5200FF;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}
.remember-text {
  margin: 0;
  line-height: 32px;
  font-weight: 400;
  font-size: 14px;
  color: #1E1E1E;
  user-select: none;
}
.login-form-container {
  width: 100%;
}
.login-btn {
  border-radius: 20px;
  width: 100%;
  height: 50px;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
  color: #fffffe;
}
.login-form {
  width: 100%;
}
</style>