<template>
  <div class="team-card-container flex">
    <div class="img">
      <el-image class="img" :src="require('@/assets/teams/logos/' + data.value.abbrev + '.png')" fit="cover" />
    </div>
    <h1 class="card-text flex" style="width: 20px;" v-if="data.index != null">{{ data.index }}.</h1>
    <h1 class="card-text flex">{{ data.value.abbrev }}</h1>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, ref } from 'vue'

const data = defineProps(['value', 'index']);
</script>

<style scoped>
.team-card-container {
  padding: 5px 5px 5px 15px;
  width: calc(100% - 20px);
  background-color: #EDEDED;
  border-radius: 5px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin: 10px 0;
}
.img {
  border-radius: 7px;
  height: 40px;
  width: 40px;
  /* border-radius: 5px;
  background-color: #f5f5f5; */
  margin-right: 10px;
  user-select: none;
}
.card-text {
  color: #161616;
  font-size: 14px;
  line-height: 14px;
  font-weight: 520;
  margin: 0;
  text-align: left;
  align-items: center;
  user-select: none;
}
</style>