<template>
  <div class="home">
    <HomeLoginPanel v-if="!regestering" @registerEvent="handleRegisterEvent"></HomeLoginPanel>
    <RegisterPanel v-else @closedEvent="handleRegisterEvent" @completeEvent="handleCompleteEvent"></RegisterPanel>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import { ElNotification, ElMessage } from 'element-plus'

import HomeLoginPanel from '@/components/home/HomeLoginPanel.vue'
import RegisterPanel from '@/components/home/register/RegisterPanel.vue'

const regestering = ref(false);

const handleRegisterEvent = (value:boolean) => {
  // console.log('Received custom event from child:', value);
  regestering.value = value;
};

const handleCompleteEvent = (value:boolean) => {
  if (value) {
    ElMessage({
      type: 'success',
      message: 'Register Success',
    });
  }
  else {
    ElMessage({
      type: 'error',
      message: 'Register Error',
    });
  }
}

</script>
<style>
.el-notification__title {
  color: #161616 !important;
}
</style>

<style scoped>
.home {
  height: 100%;
}
.user-panel {
  margin-top: 40px;
  /* background-color: #fffffe; */
  /* border-radius: 5px; */
  height: 550px;
}
</style>