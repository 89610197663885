<template>
  <div class="tip-panel">
    <div class="tip-panel-header flex">
      <div class="header-left flex">
        <h1 class="header-title flex" 
        v-loading="compLoading"
        element-loading-background="rgba(245, 245, 245, 0.3)"
        >
          <div class="header-container flex">
            <el-icon style="margin: 0 10px; cursor: pointer;" @click="redirect(-1)"><ArrowLeft /></el-icon>
            AFL {{ compRounds[roundIndex]?.round != 0 ? `Round ${compRounds[roundIndex]?.round || ''}` : 'Openning Round' }}
            <el-icon style="margin: 0 10px; cursor: pointer;" @click="redirect(1)"><ArrowRight /></el-icon>
          </div>
          <el-button color="#5200FF" class="submit-btn" @click="save" :disabled="!(compRounds[roundIndex]?.status == 'active')">Submit Tips</el-button>
          <!-- <div class="link-text flex">
            details
            <el-icon style="font-size: 12px;"><Right /></el-icon>
          </div> -->
        </h1>

        <!-- <el-affix :offset="100"> -->
        <div class="left-bottom flex">
          <!-- <el-button type="primary" class="shift-btn" color="#3DA9FC" @click="redirect(-1)">PREV</el-button> -->
          <h1 class="lock-text"
          v-if="!(compRounds[roundIndex]?.status == 'active')"
          v-loading="compLoading"
          element-loading-background="rgba(245, 245, 245, 0.3)"
          >
            This Round is <span style='font-weight: 700;'>Locked</span> Now
          </h1>
          <h1 class="lock-text"
          v-else-if="daysBetweenNow(new Date(compRounds[roundIndex]?.lockDate)) > 1"
          v-loading="compLoading"
          element-loading-background="rgba(245, 245, 245, 0.3)"
          >
            Round closes in <span style="font-weight: 700;">{{ daysBetweenNow(new Date(compRounds[roundIndex]?.lockDate)) }}</span> days.
          </h1>
          <h1 class="lock-text"
          v-else
          v-loading="compLoading"
          element-loading-background="rgba(245, 245, 245, 0.3)"
          >
            Round closes in <span style="font-weight: 700;">{{ daysBetweenNow(new Date(compRounds[roundIndex]?.lockDate)) }}</span> day.
          </h1>
          <h1 class="title-text flex">
            Express tip this round?
            <el-checkbox style="margin: 2px 0 0 7px;" v-model="express" @change="handleExpressTip" :disabled="!(compRounds[roundIndex]?.status == 'active')"></el-checkbox>
          </h1>
          <!-- <el-button type="primary" class="shift-btn" color="#3DA9FC" @click="redirect(1)">NEXT</el-button> -->
        </div>
        <!-- </el-affix> -->
        
      </div>
      <!-- <div class="header-right">
        <h1 class="header-title flex">
          <div class="title-text flex">
            Express Tipping
            <el-checkbox style="zoom:150%; margin-left: 5px;" v-model="express" @change="handleExpressTip"></el-checkbox>
          </div>
          <div class="link-text flex">
            rules
            <el-icon style="font-size: 12px;"><Right /></el-icon>
          </div>
        </h1>
        <div class="header-right-text flex">
          You can Express Tip this round by your own ladder chosen
        </div>
      </div> -->
    </div>
    <div class="tip-panel-content">
      <el-scrollbar>
        <div v-if="games.length == 0" 
        style="height: calc(100vh - 156px - 50px - 30px - 115px - 30px); min-height: calc(400px - 30px - 115px - 30px);"
        v-loading="gamesLoading"
        element-loading-background="rgba(245, 245, 245, 0.3)"
        >
        </div>

        <div v-else v-for="game in games" class="selection-card" v-loading="selectionLoading" element-loading-background="rgba(245, 245, 245, 0.3)">
          <h1 class="selection-card-header flex">
            <div style="flex:1" />
            {{ game.date.split('T')[0] }} -- {{ game.venue }} -- {{ game.date.split('T')[1].slice(0, -3) }}
            <div style="flex:1" />
          </h1>
          <div class="selection-card-content flex">
            <div class="content-left flex" @click="radio[game?.gid]=`${game?.gid}-1`">
              <el-radio v-model="radio[game?.gid]" :label="`${game?.gid}-1`" style="margin: 0 30px 0 15px;">{{""}}</el-radio>
              <div class="flex">
                <div class="img" style="margin-right: 15px;" >
                  <el-image class="img" :class="{'selected-border':radio[game?.gid]==`${game?.gid}-1`}" :src="require('@/assets/teams/logos/' + (game.teamA?.abbrev || 'default') + '.png')" fit="cover" />
                </div>
                <h1 class="team-text flex" :class="{selected:radio[game?.gid]==`${game?.gid}-1`}" style="text-align: left;">
                  {{ game.teamA?.name || 'TBD' }}
                  <div style="flex:1"/>
                </h1>
              </div>
            </div>
            <h1 class="mid-text flex" style="background-color: #EDEDED; padding: 0 10px;">
              vs
            </h1>
            <div class="content-right flex" @click="radio[game?.gid]=`${game?.gid}-2`">
              <div style="flex:1"/>
              <div class="flex">
                <h1 class="team-text flex" :class="{selected:radio[game?.gid]==`${game?.gid}-2`}" style="text-align: right;">
                  <div style="flex:1"/>
                  {{ game.teamB?.name || 'TBD' }}
                </h1>
                <div class="img" style="margin-left: 15px;" >
                  <el-image class="img" :class="{'selected-border':radio[game?.gid]==`${game?.gid}-2`}" :src="require('@/assets/teams/logos/' + (game.teamB?.abbrev || 'default') + '.png')" fit="cover" />
                </div>
              </div>
              <el-radio v-model="radio[game?.gid]" :label="`${game?.gid}-2`" style="margin: 0 15px 0 30px;">{{""}}</el-radio>
            </div>
          </div>
        </div>
        <!-- <div class="flex">
          <el-button type="primary" class="submit-btn" color="#3DA9FC" @click="save">SUBMIT</el-button>
        </div> -->
      </el-scrollbar>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { watch, onBeforeMount, Ref, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import { daysBetweenNow } from '@/utils/date'

import competitionApi from '@/api/competition'
import gameApi from '@/api/game'
import selectionApi from '@/api/selection'
import ladderApi from '@/api/ladder'

const route = useRoute();
const router = useRouter();

const roundNumber = ref(24);
const tip = route.params.tip;
// const round = ref(Number(route.params.round));
const compInfo = ref();
const compRounds:any = ref([]);
const roundIndex = ref(0);
const games:any = ref([]);
const selections:any = ref([]);
const preferences:any = ref([]);

const compLoading = ref(true);
const gamesLoading = ref(true);
const selectionLoading = ref(true);

const redirect = (diff:number) => {
  // let r = round.value + diff;
  // if (r >= 0 && r <= roundNumber.value) {
  //   router.push(`/tip/${route.params.tip}/${r}`);
  //   round.value = r;
  // }
  let r = roundIndex.value + diff;
  if (r >= 0 && r < roundNumber.value) {
    roundIndex.value = r;
    express.value = false;
    // console.log(roundIndex.value);
  }
}

const express = ref();
const radio:Ref<string[]> = ref([]);

const initGames = () => {
  let query = {
    season:compInfo.value.season,
    spid:compInfo.value.sport?.spid,
    round:compRounds.value[roundIndex.value]?.round
  };
  gameApi.gameByRound(query).then(res => {
    if (res && res.code == 1 && res.response) {
      games.value = res.response.roundInfo?.games || null;
      
    }
    gamesLoading.value = true;
    
    // console.log(res);
  }).catch(err => {
    gamesLoading.value = true;
    ElMessage({
      type: 'error',
      message: err,
    });
    // console.log(err);
  });
}

const initSelections = () => {
  selectionLoading.value = true;

  let query = {
    cuid:tip,
    round:compRounds.value[roundIndex.value]?.round,
    season:compInfo.value.season
  };
  selectionApi.selectionsPerRound(query).then(res => {
    if (res && res.code == 1) {
      selections.value = res.response;
      select(res.response);
    }
    selectionLoading.value = false;
  }).catch(err => {
    selectionLoading.value = false;
    ElMessage({
      type: 'error',
      message: err,
    });
    // console.log(err);
  });
}

const select = (s:any) => {
  s.forEach((selection:any) => {
    if (selection.result == 'h') {
      radio.value[selection.gid] = `${selection.gid}-1`;
    }
    else if (selection.result == 'a') {
      radio.value[selection.gid] = `${selection.gid}-2`;
    }
  });
}

const clearSelect = () => {
  games.value.forEach((game:any) => {
    radio.value[game.gid] = '';
  });
}

const handleExpressTip = (val:boolean) => {
  if (val) {
    expressTip();
  }
  else {
    clearSelect();
    select(selections.value);
  }
}

const expressTip = () => {
  games.value.forEach((game:any) => {
    let teamAId = game.teamA?.id;
    let teamBId = game.teamB?.id;
    let len = preferences.value.length;

    for (let i = 0; i < len; i++) {
      let p = preferences.value[i];
      if (p.id == teamAId) {
        radio.value[game.gid] = `${game.gid}-1`;
        break;
      }
      else if (p.id == teamBId) {
        radio.value[game.gid] = `${game.gid}-2`;
        break;
      }
    }
  });
}

const save = () => {
  let query:any[] = [];
  games.value.forEach((game:any) => {
    let gid = game.gid;
    let result = null;
    if (radio.value[gid] == `${gid}-1`) {
      result = 'h';
    }
    else if (radio.value[gid] == `${gid}-2`) {
      result = 'a';
    }
    let q = {
      cuid:tip,
      gid:gid,
      round:compRounds.value[roundIndex.value]?.round || null,
      season:compInfo.value.season || null,
      result:result,
      cid:compInfo.value.cid
    }
    query.push(q);
  });
  
  selectionApi.save(query).then(res => {
    if (res && res.code == 1) {
      ElMessage({
        type: 'success',
        message: 'Save Success',
      });
      // ElNotification.success({
      //   title: 'Success',
      //   // message: h('h1', { style: 'color: #3DA9FC' }, 'Sign Up Successfully'),
      //   message: 'Save Successfully',
      // });
      initSelections();
    }
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
  })
}

watch(
  () => roundIndex.value,
  () => {
    initGames();
    initSelections();
  }
);

onBeforeMount(async () => {

  ladderApi.preference().then(res => {
    if (res && res.code == 1) {
      preferences.value = res.response;
    }
    // console.log(res);
  }).catch(err => {
    console.log(err);
  });

  try {
    let res = await competitionApi.competitionByTipID({ cuid: tip });

    if (res && res.code === 1 && res.response) {
      compInfo.value = res.response;
      compLoading.value = false;

      let cid = res.response.cid;
      res = await competitionApi.competitionRound({cid:cid});
      if (res && res.code == 1 && res.response
      && res.response.competitionRounds && res.response.competitionRounds.length != 0) {

        compRounds.value = res.response.competitionRounds;
        roundNumber.value = res.response.competitionRounds.length;
        roundIndex.value = res.response.index != -1 ? res.response.index : 0;
        // console.log(res);
        initGames();
        initSelections();
      }

    }
  } catch (err:any) {
    ElMessage({
      type: 'error',
      message: err,
    });
    compLoading.value = false;
  }

});

</script>

<style>
.title-text .el-checkbox__inner {
  width: 20px;
  height: 20px;
}

.title-text .el-checkbox__inner::after {
  border: 3px solid #fff;
  border-left: 0;
  border-top: 0;
  left: 7px;
  top: 4px;
}
 
.title-text .el-checkbox__input.is-checked .el-checkbox__inner::after {
  transform: rotate(50deg) scaleY(1.3);

}

.title-text .is-checked .el-checkbox__inner {
  background-color: #5200FF;
  /* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25); */
  border: 0;
}
.title-text .el-checkbox__inner {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
}
.title-text .el-checkbox__inner:hover {
  /* background-color: #5200FF; */
  border-color: #5200FF;
}

.el-image {
  user-select: none;
}
.selection-card-content .el-radio__inner {
    width: 30px;
    height: 30px;
    /* border-color: #5200FF; */
    border: 2px solid #E6E6E8;
    background-color: #D9D9D9;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    &::after {
        /* display: none; */
        /* transition: none; */
        /* transform: translate(-50%,-50%) scale(1); */
        width: 20px;
        height: 20px;
        background-color: #F5F5F5;
    }
}
.selection-card-content .el-radio__input.is-checked {
    .el-radio__inner {
        /* padding: 4px; */
        background-clip: content-box;
        background-color: #5200FF;
        border-color: #5200FF;
    }
}
</style>

<style scoped>
.el-radio {
  height: 30px;
  width: 30px;
  --el-radio-input-border-color-hover: #5200FF;
}
.tip-panel {
  height: calc(100% - 30px);
  min-height: calc(400px- 30px);
  border-radius: 20px;
  background-color: #F5F5F5;
  padding: 15px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
.submit-btn {
  width: 180px;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  color: #fffffe;
  border-radius: 20px;
}
.mid-text {
  color: #161616;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin: 0;
  text-align: center;
  align-items: center;
  user-select: none;
}
.team-text {
  color: #161616;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  text-align: center;
  align-items: center;
  user-select: none;
  width: 135px;
}
.img {
  height: 60px;
  width: 60px;
  border-radius: 20px;
  /* border-radius: 5px;
  background-color: #f5f5f5; */
  /* margin-right: 10px; */
}
.selection-card-content {
  height: 88px;
}
.selected {
  /* background-color: rgba(144, 180, 206, 0.15) !important; */
  color: #5200FF;
  font-weight: 700;
}
.selected-border {
  /* background-color: rgba(144, 180, 206, 0.15) !important; */
  border: 3px solid #5200FF;
  height: 57px !important;
  width: 57px !important;
}
.content-left {
  width: calc(100% - 1px);
  height: 100%;
  /* border-right: 1px solid #D9D9D9; */
  /* border-radius: 0 0 0 20px;
  background-color: #EDEDED; */
  align-items: center;
  cursor: pointer;
}
.content-right {
  width: calc(100% - 1px);
  height: 100%;
  /* border-left: 1px solid #D9D9D9; */
  /* border-radius: 0 0 20px 0;
  background-color: #EDEDED; */
  align-items: center;
  cursor: pointer;
}
.selection-card-header {
  height: 30px;
  /* border-radius: 20px 20px 0 0;
  background-color: #EDEDED; */
  margin: 0;
  text-align: left;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  /* border-bottom: 2px solid #D9D9D9; */
}
.tip-panel-content {
  margin-top: 30px;
  height: calc(100vh - 156px - 50px - 30px - 115px - 30px);
  min-height: calc(400px - 30px - 115px - 30px);
}
.selection-card {
  /* height: 140px; */
  margin-bottom: 30px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  margin-left: 10px;
  margin-right: 10px;
  min-width: 600px;
  border-radius: 20px;
  background-color: #EDEDED;
}
.header-right-text {
  padding: 0 20px;
  height: 55px;
  text-align: left;
  font-size: 14px;
  align-items: center;
}
.el-checkbox {
  height: 13px;
}
.shift-btn {
  width: 80px;
  height: 40px;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
  color: #fffffe;
}
.left-bottom {
  height: 40px;
  justify-content: space-between;
}
.lock-text {
  /* border-radius: 5px;
  background-color: rgba(144, 180, 206, 0.15); */
  /* width: 330px; */
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: #161616;
  margin: 10px 0 0 0;
  padding-left: 25px;
  align-items: center;
  text-align: left;
}
.header-right {
  width: calc(100% - 520px - 30px);
  border-radius: 5px;
  background-color: rgba(144, 180, 206, 0.15);
}
.header-left {
  flex-direction: column;
  justify-content: space-between;
  /* width: 520px; */
  width: 100%;
}
.header-title {
  height: 60px;
  /* padding: 0 20px; */
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  align-items: center;
  justify-content: space-between;
  margin:0;
  color: #1D1D1D;
}
.header-container {
  height: 50px;
  background-color: #EDEDED;
  border-radius: 20px;
  align-items: center;
  padding: 0 15px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  user-select: none;
}
.link-text {
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  color: #5F6C7B;
  align-items: flex-end;
  user-select: none;
  height: 20px;
  cursor: pointer;
}
.title-text {
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: #161616;
  margin: 10px 0 0 0;
  /* align-items: center; */
  padding-right: 25px;
  text-align: right;
}
.tip-panel-header {
  justify-content: space-between;
  width:100%;
  height: 115px;
}
</style>