<template>
  <div class="form-container flex" v-loading="loading" element-loading-background="rgba(245, 245, 245, 0.3)">
    <el-form 
      label-position="left"
      :model="form"
      class="signup-form"
    >
      <h1 class="form-text flex">
          Enter the code
      </h1>
      <el-row>
        <el-col :span="8">
          <el-form-item label="">
            <el-input v-model="form.code" style="height: 40px;" placeholder="Confirmation Code"/>
          </el-form-item>
        </el-col>
      </el-row>
      <h1 v-if="isSending" class="form-text flex" style="font-size: 16px;">{{ `Resend in ${countdown} seconds` }}</h1>
      <h1 v-else class="form-text flex" style="font-size: 16px;">We’ve just sent a confirmation code to your email</h1>
      <el-row>
        <el-button type="primary" class="resend-btn" color="#D9D9D9" 
        @click="sendEmailCode" 
        :disabled="isSending || countdown > 0"
        >Resend the code</el-button>
      </el-row>
    </el-form>
    <div class="form-footer-container flex">
      <el-button type="primary" class="submit-btn" color="#5200FF" @click="emits('confirmEvent', -1);">PREV</el-button>
      <el-button type="primary" class="submit-btn" color="#5200FF" @click="checkCode">NEXT</el-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, onBeforeMount, ref, reactive, defineProps } from 'vue'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import registerApi from '@/api/register'

onBeforeMount(() => {
  
});

const emits = defineEmits(['confirmEvent']);
const data = defineProps(['value']);

const loading = ref(false);
const isSending = ref(false);
const countdown = ref(0);

const form = reactive({
  code: ''
});

const checkCode = () => {
  loading.value = true;
  registerApi.checkEmailCode({email:data.value, code:form.code}).then(res => {
    if (res && res.code == 1) {
      loading.value = false;
      emits('confirmEvent', 1);
    }
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
    loading.value = false;
    // console.log(err);
  });
}

const sendEmailCode = () => {
  if (countdown.value > 0 || isSending.value) {
    return; 
  }
  isSending.value = true;
  startCountdown();
  registerApi.sendEmailCode({email:data.value}).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
  });
}

const startCountdown = () => {
  countdown.value = 60;
  const timer = setInterval(() => {
    countdown.value--;
    if (countdown.value <= 0) {
      isSending.value = false;
      clearInterval(timer);
    }
  }, 1000);
}

</script>

<style scoped>
.form-container {
  height: 320px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.form-text {
  font-weight: 500;
  font-size: 18px;
  margin: 0 0 18px 0;
  color: #161616;
}
.form-footer-container {
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}
.resend-btn {
  width: 200px;
  height: 50px;
  font-size: 18PX;
  font-weight: 500;
  /* box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1); */
  /* color: #F5F5F5; */
  border-radius: 12px;
}
.submit-btn {
  width: 200px;
  height: 50px;
  font-size: 18PX;
  font-weight: 500;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  color: #F5F5F5;
  border-radius: 12px;
}
.signup-form {
  width: 100%;
}
</style>