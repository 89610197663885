const daysBetween = (date1:Date, date2:Date) => {
  const timeDiff = Math.abs(date1.getTime() - date2.getTime());
  const daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  return daysDiff;
}

const daysBetweenNow = (date:Date) => {
  const cur = new Date();

  return daysBetween(date, cur);
}

const beforeDate = (date:Date) => {
  const cur = new Date();
  if (cur < date) {
    return true;
  }
  else {
    return false;
  }
}

export {
  daysBetween,
  daysBetweenNow,
  beforeDate
}