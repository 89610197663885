<template>
  <div class="box-background img-background flex">
    <div style="flex: 1;" />
    <div class="flex">
      <div style="flex: 1;" />
      <div class="box" v-loading="loading" element-loading-background="rgba(245, 245, 245, 0.3)">
        <el-row class="header-background">
          <div style="flex: 1;" />
          <el-steps class="steps" :active="active" align-center finish-status="success">
            <el-step title="Form" />
            <el-step title="Confirm" />
            <el-step title="Details" />
          </el-steps>
          <div style="flex: 1;" class="close-btn flex">
            <div style="flex: 1;" />
            <el-icon color="#5200FF" @click="emits('closedEvent', false)"><CloseBold/></el-icon>
          </div>
        </el-row>
        <div class="main-background">
          <div v-if="active == 0" class="signup-form-container">
            <RegisterForm @checkEvent="handleCheck"></RegisterForm>
          </div>
          <div v-if="active == 1" class="signup-form-container">
            <ConfirmForm @confirmEvent="handleConfirm" :value="submitForm.email"></ConfirmForm>
          </div>
          <div v-if="active == 2" class="signup-form-container">
            <DetailForm @registerEvent="handleRegister"></DetailForm>
          </div>
        </div>
      </div>
      <div style="flex: 1;" />
    </div>
    <div style="flex: 1;" />
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref } from 'vue'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import RegisterForm from '@/components/home/register/RegisterForm.vue'
import ConfirmForm from '@/components/home/register/ConfirmForm.vue'
import DetailForm from '@/components/home/register/DetailForm.vue'
import registerApi from '@/api/register'

const emits = defineEmits(['closedEvent', 'completeEvent']);

const active = ref(0);
const loading = ref(false);

const submitForm = ref({
  username: '',
  password: '',
  email: '',
  phone: '',
  gender: '',
  birth: '',
  address: '',
  city: '',
  state: '',
  postcode: 1234,
});

const handleCheck = (value: number, rForm: any) => {
  active.value += value;

  // submitForm.username = rForm.username;
  // submitForm.password = rForm.password;
  // submitForm.email = rForm.email;

  submitForm.value = { ...submitForm.value, ...rForm };

  // console.log(submitForm.value)
}

const handleConfirm = (value: number) => {
  active.value += value;
}

const handleRegister = (value: number, dForm: any) => {
  active.value += value;

  submitForm.value = { ...submitForm.value, ...dForm };

  submitForm.value.birth = new Date(submitForm.value.birth).toISOString();

  // console.log(submitForm.value)

  if (active.value == 3) {
    loading.value = true;
    registerApi.register(submitForm.value).then(res => {
      if (res.code == 1) {
        loading.value = false;
        emits('closedEvent', false);
        emits('completeEvent', true);
      }

      // console.log(res);
    }).catch(err => {
      loading.value = false;  
      ElMessage({
        type: 'error',
        message: err,
      });

      // console.log(err);
    });
    
  }
}

</script>

<style>
/* .signup-form .el-form-item__label {
  font-size: 18px;
  font-weight: 500;
  color: #094067;
} */
.signup-form .el-input__wrapper {
  background-color: transparent;
  border-radius: 10px;
  border: 0.5px solid #2E2E2E;
}
.signup-form .el-input__wrapper:hover {
  border: 0.5px solid #5200FF;
}
.signup-form .el-input__wrapper.is-focus {
  border: 0.5px solid #5200FF;
}
.signup-form .el-input {
  --el-input-focus-border-color: #5200FF !important;
}
.signup-form .el-input__inner {
  background-color: transparent;
}

.signup-form input:-webkit-autofill,
.signup-form input:-webkit-autofill:hover,
.signup-form input:-webkit-autofill:focus {
  border: 1px solid #dcdee2;
  -webkit-text-fill-color: #17233d;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* .box-background .el-step__icon {
  background-color: rgba(245, 245, 245, 1);
} */
</style>

<style scoped>
.signup-form-container {
  width: 100%;
}
.el-icon {
  cursor: pointer;
  margin-right: 30px;
}
.el-icon:hover {
  background-color: #5200FF;
  border-radius: 10px;
  color: #fffffe;
}
.close-btn {
  font-size: 30px;
  align-items: center;
}
/* h1 {
  color:#094067;
  font-size: 26px;
  font-weight: 600;
} */
.steps {
  margin-top: 15px;
  width: 300px;
}
.main-background {
  padding: 50px 50px;
  height: 370px;
}
.header-background {
  height: 80px;
  border-bottom: 2px solid #D9D9D9;
  box-sizing: border-box;
}
.box {
  background-color: rgba(245, 245, 245, 0.9);
  /* border-top: 5px solid #094067; */
  border-radius: 20px;
  height: 500px;
  width: 700px;
  min-width: 700px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
}
.box-background {
  padding: 0;
  margin: 0;
  /* background-color: #fffffe; */
  /* border-radius: 0 5px 5px 0; */
  height: 100vh;
  min-height: 650px;
  min-width: 700px;
  flex-direction: column;
}
.img-background {
  background-image: url('@/assets/background.jpg');
  background-size: cover !important;
  background-position: center;
}
</style>