<template>
  <div class="comp-view">
    <el-row class="page-header-container">
      <el-col>
        <!-- <WelcomePanel></WelcomePanel> -->
        <h1 class="page-header-text">
          Resulted Competitions
        </h1>
      </el-col>
    </el-row>
    <div class="tipping-panel-container">
      <CompetitionPanel :value="type"></CompetitionPanel>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import CompetitionPanel from '@/components/competition/CompetitionPanel.vue'

const type = ref('history');
</script>

<style scoped>
.comp-view {
  margin-left: 50px;
}
.page-header-text {
  margin: 0;
  font-size: 36px;
  line-height: 36px;
  text-align: left;
  font-weight: 500;
  user-select: none;
}
.page-header-container {
  margin-bottom: 70px;
  margin-top: 50px;
}
.tipping-panel-container {
  margin-top: 15px;
  width: calc(100vw - 400px);
}
</style>