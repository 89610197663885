<template>
  <div>
    <el-menu
      router
      :default-active="route.path"
      class="menu-vertical"
      background-color="#5200FF"
      text-color="rgba(255, 255, 254, 0.7)"
      active-text-color="rgba(255, 255, 254, 1)"
      @open="handleOpen"
      @close="handleClose"
    >
      <el-row class="header" @click="backToHome">
        <!-- <el-image :src="require('@/assets/img/logo.svg')" fit="cover" /> -->
        <img :src="require('@/assets/img/logo.svg')">
      </el-row>
      <el-scrollbar class="scroll">
          <el-menu-item index="/dashboard">
              <el-icon><House /></el-icon>
              <!-- <el-icon><HomeFilled /></el-icon> -->
              Dashboard
          </el-menu-item>
          <el-menu-item index="/ladder">
              <!-- <el-icon><Medal /></el-icon> -->
              <el-icon><Star /></el-icon>
              My Ladder
          </el-menu-item>
          <el-sub-menu index="/tipping">
              <template #title>
                  <!-- <el-icon><CollectionTag /></el-icon> -->
                  <el-icon><Avatar /></el-icon>
                  My Tips
              </template>
              <el-menu-item index="/current-tipping">
                  <div class="sub-menu-margin">
                      Current Tipping
                  </div>
              </el-menu-item>
              <el-menu-item index="/history-tipping">
                  <div class="sub-menu-margin">
                      History
                  </div>
              </el-menu-item>
          </el-sub-menu>
          <el-sub-menu index="/competition">
              <template #title>
                  <el-icon><Soccer /></el-icon>
                  Competitions
              </template>
              <el-menu-item index="/current-competition">
                  <div class="sub-menu-margin">
                      Active
                  </div>
              </el-menu-item>
              <el-menu-item index="/archive">
                  <div class="sub-menu-margin">
                      Resulted
                  </div>
              </el-menu-item>
          </el-sub-menu>
          <!-- <el-menu-item index="/achivement">
              <el-icon><TrophyBase /></el-icon>
              Achivement
          </el-menu-item> -->
          <el-sub-menu index="/order-management">
              <template #title>
                  <el-icon><ShoppingCart /></el-icon>
                  Manage
              </template>
              <el-menu-item index="/pending-order">
                  <div class="sub-menu-margin">
                      Pending
                  </div>
              </el-menu-item>
              <el-menu-item index="/complete-order">
                  <div class="sub-menu-margin">
                      Complete
                  </div>
              </el-menu-item>
          </el-sub-menu>
          <!-- <el-menu-item index="/order">
              <el-icon><ShoppingCart /></el-icon>
              Order Management
          </el-menu-item> -->
          <!-- <el-menu-item index="/setting">
              <el-icon><setting /></el-icon>
              Setting
          </el-menu-item> -->
      </el-scrollbar>
    </el-menu>
  </div>
</template>

<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const backToHome = () => {
  router.push('/home');
}
const handleOpen = (key: string, keyPath: string[]) => {
  // console.log(key, keyPath)
}
const handleClose = (key: string, keyPath: string[]) => {
  // console.log(key, keyPath)
}
</script>

<style>
.el-sub-menu .el-sub-menu__title {
  font-size: 16px;
  font-weight: 500;
  user-select: none;
  margin-left: 10px;
}
.el-sub-menu .el-sub-menu__title:hover{
  /* background-color: rgba(61, 169, 252, 0.5) !important; */
  background-color: transparent !important;
  color: #fffffe !important;
}
</style>

<style scoped>
.sub-menu-margin {
  margin-left: 20px;
}
.el-menu-item {
  font-size: 16px;
  font-weight: 500;
  user-select: none;
  margin-left: 10px;
}

.el-menu-item:hover{
  /* background-color: rgba(61, 169, 252, 0.5) !important; */
  background-color: transparent !important;
  color: #fffffe !important;
}
.menu-vertical {
  height: 100vh;
  box-shadow: 4px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.header {
  height: 140px;
  cursor: pointer;
  user-select: none;
  padding: 20px;
}
.scroll {
  height: calc(100vh - 140px);
}
</style>