<template>
  <h1 v-if="data.value==1" class="card win flex">
    <div style="flex:1;"/>
    W
    <div style="flex:1;"/>
  </h1>
  <h1 v-else-if="data.value==-1" class="card lost flex">
    <div style="flex:1;"/>
    L
    <div style="flex:1;"/>
  </h1>
</template>

<script lang="ts" setup>
import { defineProps, ref } from 'vue'

const data = defineProps(['value']);
</script>

<style scoped>
.win {
  background-color: #5200FF;
}
.lost {
  background-color: #EF4565;
}
.card {
  height: 24px;
  width: 24px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 300;
  border-radius: 5px;
  margin: 0;
  text-align: center;
  align-items: center;
  color: #fffffe;
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.25);
  user-select: none;
}
</style>