<template>
  <div class="comp-card flex">
    <div class="comp-card-content">
      <h1 class="comp-card-data">
        Competition ID: {{ data.value.cid }}
      </h1>
      <el-row :gutter="15">
        <el-col :xs="24" :sm="16" :md="24" :lg="16" :xl="16" class="comp-card-left">
          <div class="comp-card-row flex">
            <h1 class="comp-card-header left-text">
              Punters
            </h1>
            <h1 class="comp-card-data">
              {{ data.value.punterNumber }}
            </h1>
          </div>
          <div class="comp-card-row flex">
            <h1 class="comp-card-header left-text">
              Entry Fee
            </h1>
            <h1 class="comp-card-data">
              {{ data.value.entryFee }}
            </h1>
          </div>
        </el-col>
        <el-col :xs="24" :sm="8" :md="24" :lg="8" :xl="8" class="comp-card-row">
          <div class="comp-card-right flex-column">
            <h1 class="comp-card-header flex" >
              Prize Pool
            </h1>
            <h1 class="comp-card-data flex" style="font-size: 24px; margin-top: 10px;">
              {{ data.value.prizePool?.slice(0, -3) }}
            </h1>
          </div>
        </el-col>
      </el-row>
      <el-button color="#F5F5F5" class="comp-btn" @click="join">Enter Comp</el-button>
    </div>
    <!-- <div class="arrow flex">
      <el-icon><ArrowRight /></el-icon>
    </div> -->
  </div>
</template>

<script lang="ts" setup>
import { defineProps, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElLoading,ElMessage } from 'element-plus'

const router = useRouter();

const data = defineProps(['value']);

const join = () => {
  let loadingInstance = ElLoading.service({ fullscreen: true });
  router.push('/join/'+data.value.cid);
  loadingInstance.close();
}
</script>

<style scoped>
.comp-btn {
  border-radius: 20px;
  margin-top: 15px;
  width: 100%;
  height: 40px;
  font-weight: 500;
  font-size: 18px;
}
.comp-card-right {
  justify-content: space-between;
  height: 100%;
}
.comp-card-content {
  width: 100%;
}
.left-text {
  width: 120px;
}
.comp-card-row {
  margin-top: 15px;
}
.comp-card-header {
  color: #8A8A8A;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  /* margin: 0 5px 0 0; */
  margin: 0;
  text-align: left;
}
.comp-card-data {
  color: #161616;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin: 0;
  text-align: left;
}
.comp-card{
  background-color: #EDEDED;
  border-radius: 20px;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 0 15px 15px 15px;
  justify-content: space-between;
  /* cursor: pointer; */
}
/* .comp-card:hover{
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
} */
.arrow {
  align-items: center;
}
/* .comp-card-content {
  align-items: center;
} */
</style>