<template>
  <div class="result-view">
    <el-row class="page-header-container">
      <el-col>
        <h1 class="page-header-text">
          Result
        </h1>
      </el-col>
    </el-row>
    <div class="tipping-panel-container">
      <ResultPanel></ResultPanel>
    </div>
    <div style="height: 50px;"></div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import ResultPanel from '@/components/result/ResultPanel.vue'

const type = ref('current');
</script>

<style scoped>
.result-view {
  margin-left: 50px;
}
.page-header-text {
  margin: 0;
  font-size: 36px;
  line-height: 36px;
  text-align: left;
  font-weight: 500;
  user-select: none;
}
.page-header-container {
  margin-bottom: 70px;
  margin-top: 50px;
}
.tipping-panel-container {
  width: calc(100vw - 400px);
}
</style>