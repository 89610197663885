<template>
  <div class="form-container flex" v-loading="loading" element-loading-background="rgba(245, 245, 245, 0.3)">
    <el-form 
      label-position="left"
      :model="form"
      class="signup-form"
      ref="registerFormRef"
      :rules="rules"
    >
      <el-form-item label="" prop="email">
        <el-input v-model="form.email" placeholder="Email" class="form-input"/>
      </el-form-item>
      <el-form-item label="" prop="username">
        <el-input v-model="form.username" placeholder="Username" class="form-input"/>
      </el-form-item>
      <el-form-item label="" prop="password">
        <el-input v-model="form.password" type="password" autocomplete="new-password" placeholder="Password" class="form-input"/>
      </el-form-item>
      <el-form-item label="" prop="confirmPassword">
        <el-input v-model="form.confirmPassword" type="password" placeholder="Confirm Password" class="form-input"/>
      </el-form-item>
    </el-form>
    <div class="form-footer-container flex">
      <div style="flex:1" />
      <!-- <el-button type="primary" class="submit-btn" color="#3DA9FC" @click="next">PREV</el-button> -->
      <el-button type="primary" class="submit-btn" color="#5200FF" @click="submit(registerFormRef)">NEXT</el-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { inject, onBeforeMount, ref, reactive } from 'vue'
import type { FormInstance, FormRules } from 'element-plus'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import registerApi from '@/api/register'

const emits = defineEmits(['checkEvent']);

const loading = ref(false);

const form = reactive({
  username: '',
  password: '',
  confirmPassword: '',
  email: ''
});

const registerFormRef = ref<FormInstance>();

const validateUsername = (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error('Please input the username'));
  }
  else {
    callback()
  }
}

const validateEmail= (rule: any, value: any, callback: any) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!value) {
    callback(new Error('Please input the email'));
  }
  else if (!emailRegex.test(value)) {
    callback(new Error('Please use correct email format'));
  }
  else {
    callback()
  }
}

const validatePassword = (rule: any, value: any, callback: any) => {
  if (!value) {
    callback(new Error('Please input the password'));
  }
  else {
    callback()
  }
}

const validateConfirmPass = (rule: any, value: any, callback: any) => {
  if (value === '') {
    callback(new Error('Please input the password again'));
  } else if (value !== form.password) {
    callback(new Error("Two inputs don't match!"));
  } else {
    callback()
  }
}

const rules = reactive<FormRules<typeof form>>({
  username: [{ validator: validateUsername, trigger: 'blur' }],
  password: [{ validator: validatePassword, trigger: 'blur' }],
  confirmPassword: [{ validator: validateConfirmPass, trigger: 'blur' }],
  email: [{ validator: validateEmail, trigger: 'blur' }],
});


const submit = (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  formEl.validate(valid => {
    if (valid) {
      loading.value = true;
      registerApi.registerCheck({username: form.username, email: form.email}).then((res) => {
        if (res && res.code == 1) {
          registerApi.sendEmailCode({email:form.email}).catch(err => {
            console.log(err);
          });
          emits('checkEvent', 1, {username: form.username, password: form.password, email: form.email});
        }
        loading.value = false;
        // console.log(res);
      }).catch(err => {
        ElMessage({
          type: 'error',
          message: err,
        });
        loading.value = false;  
      });
    } 
    else {
      // return false;
    }
  });
}

</script>

<style scoped>
.form-input {
  height: 40px;
}
.form-container {
  height: 320px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.form-footer-container {
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}
.submit-btn {
  width: 180px;
  height: 50px;
  font-size: 18PX;
  font-weight: 500;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  /* color: #F5F5F5; */
  border-radius: 12px;
}
.signup-form {
  width: 100%;
}
</style>