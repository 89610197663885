import axios from 'axios'
import { ElLoading,ElMessage } from 'element-plus'
import router from '@/router'
import { h } from 'vue'

const request = (loadtip: boolean, query: any) => {
  let loadingInstance:any;
  if (loadtip) {
    loadingInstance = ElLoading.service({ fullscreen: true });
  }
  return axios.request(query)
    .then(res => {
      if (loadtip) {
        loadingInstance.close();
      }
      if (res.data.code === 401) {
        if (router.currentRoute.value.path != '/home') {
          // ElMessage.error('You need to LOGIN first.');
          ElMessage({
            message: h('p', null, [
              h('span', null, 'You need to '),
              h('span', { style: ['font-weight: 600'] }, 'login '),
              h('span', null, 'first'),
            ]),
          });
        }
        router.push('/home');
        return Promise.reject(res.data);
      } else if (res.data.code === 500) {
        return Promise.reject(res.data);
      } else if (res.data.code === 501) {
        return Promise.reject(res.data);
      } else if (res.data.code === 502) {
        ElMessage({
          message: h('p', null, [
            h('span', null, 'You don\'t have '),
            h('span', { style: ['font-weight: 600'] }, 'access '),
            h('span', null, 'to this page'),
          ]),
        });
        router.push('/dashboard');
        return Promise.reject(res.data);
      } else {
        return Promise.resolve(res.data);
      }
    })
    .catch(e => {
      if (loadtip) {
        loadingInstance.close();
      }
      // vue.prototype.$message.error(e.message)
      return Promise.reject(e.message);
    })
}

const post = (url:string, params:any) => {
  const query = {
    baseURL: process.env.VUE_APP_API,
    url: url,
    method: 'post',
    withCredentials: true,
    timeout: 30000,
    data: params,
    headers: { 'Content-Type': 'application/json', 'request-ajax': true }
  }
  return request(false, query)
}

const postWithLoadTip = (url:string, params:any) => {
  const query = {
    baseURL: process.env.VUE_APP_API,
    url: url,
    method: 'post',
    withCredentials: true,
    timeout: 30000,
    data: params,
    headers: { 'Content-Type': 'application/json', 'request-ajax': true }
  }
  return request(true, query)
}

const postWithOutLoadTip = (url:string, params:any) => {
  const query = {
    // baseURL: process.env.VUE_APP_URL,
    baseURL: process.env.VUE_APP_API,
    url: url,
    method: 'post',
    withCredentials: true,
    timeout: 30000,
    data: params,
    headers: { 'Content-Type': 'application/json', 'request-ajax': true }
  }
  return request(false, query)
}

const get = (url:string, params:any) => {
  const query = {
    // baseURL: process.env.VUE_APP_URL,
    baseURL: process.env.VUE_APP_API,
    url: url,
    method: 'get',
    withCredentials: true,
    timeout: 30000,
    params: params,
    headers: { 'request-ajax': true }
  }
  return request(false, query)
}

const form = (url:string, params:any) => {
  const query = {
    baseURL: process.env.VUE_APP_API,
    url: url,
    method: 'post',
    withCredentials: true,
    timeout: 30000,
    data: params,
    headers: { 'Content-Type': 'multipart/form-data', 'request-ajax': true }
  }
  return request(false, query)
}

export {
  post,
  postWithLoadTip,
  postWithOutLoadTip,
  get,
  form
}