<template>
  <div class="tipping-panel" v-loading="loading" element-loading-background="rgba(245, 245, 245, 0.5)">
    <ResultTable :value="tableData"></ResultTable>
  </div>
  <div class="tipping-panel-footer flex">
    <div style="flex:1"/>
    <el-pagination
    v-model:current-page="currentPage"
    layout="prev, pager, next" 
    :background="true" 
    :total="totalCount" 
    @current-change="handleCurrentChange"
    :hide-on-single-page="true"
    :default-page-size="size"
    />
    <div style="flex:1"/>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { ElMessageBox, ElMessage, ElNotification } from 'element-plus'

import ResultTable from '@/components/result/ResultTable.vue'

import scoreboardApi from '@/api/scoreboard'
import competitionApi from '@/api/competition'

const route = useRoute();
const router = useRouter();

const tip = route.params.tip;
const input = ref();
const cid = ref();
const loading = ref(false);
const totalCount = ref(0);
const currentPage = ref(1);
const size = 13;

const data = defineProps(['value']);

const tableData: any = ref([
]);

const handleCurrentChange = (page: number) => {
  loading.value = true;

  scoreboardApi.scoreboard({cid:cid.value,page:page-1,size:size}).then(res => {
    if (res && res.code == 1) {
      tableData.value = res.response;
      console.log(res.response);
    }
    loading.value = false;
  }).catch(err => {
    loading.value = false;
    ElMessage({
      type: 'error',
      message: err,
    });
  });
}

const init = async (page:number) => {
  loading.value = true;
  await competitionApi.competitionByTipID({cuid:tip}).then(res => {
    if (res && res.code == 1) {
      cid.value = res.response.cid;
    }
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
  });

  scoreboardApi.count({cid:cid.value}).then(res => {
    if (res && res.code == 1) {
      totalCount.value = res.response;
    }
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
  });

  scoreboardApi.scoreboard({cid:cid.value,page:page-1,size:size}).then(res => {
    if (res && res.code == 1) {
      tableData.value = res.response;
      
    }
    loading.value = false;
  }).catch(err => {
    ElMessage({
      type: 'error',
      message: err,
    });
    loading.value = false;
  });
}

onBeforeMount(async () => {
  init(1);
});

</script>

<style scoped>
.tipping-panel-footer {
  margin-top: 30px;
}
.tipping-panel {
  /* height: calc(100vh - 100px - 30px - 60px); */
  /* padding: 30px; */
  border-radius: 20px;
  /* background-color: #fffffe; */
}
</style>