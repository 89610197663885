const formatWithCommas = (amount:number) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  return formatter.format(amount);
}

const addSuffix = (num:number) => {
  if (getSuffix(num) == "") return num + " th";

  return num + " " + getSuffix(num);
}

const getSuffix = (num:number) => {
  if (num >= 11 && num <= 13) {
    return "th";
  }

  switch (num % 10) {
    case 0:
      return "";
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
}

export {
  formatWithCommas,
  addSuffix,
  getSuffix
}