<template>
  <el-container class="menu-vertical">
    <el-header class="menu-header">
      <el-row>
        <!-- <el-image :src="require('@/assets/img/logo.svg')" fit="cover" /> -->
        <img :src="require('@/assets/img/logo.svg')">
      </el-row>
    </el-header>
    <el-main>
      <el-scrollbar>
        <el-row>
          <h2 class="menu-text flex">Never forget your tips again!</h2>
          <h2 class="menu-text flex">Set a ladder for the year and let Power Tipping set your tips each week.</h2>
          <h2 class="menu-text flex">Sign up now before footy season starts!</h2>
        </el-row>
      </el-scrollbar>
    </el-main>
    <el-footer class="menu-footer">
      <el-row>
        <el-icon class="footer-icon"><Message /></el-icon>
        <h2 class="footer-text flex">hello@powertipping.com</h2>
      </el-row>
      <!-- <el-row>
        <h2 class="footer-text flex">@powertipping</h2>
      </el-row> -->
    </el-footer>
  </el-container>

    <!-- <el-scrollbar style="height: 100%;">
    </el-scrollbar> -->
</template>
  
<script lang="ts" setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

// import Mail from '@/assets/icon/mail.svg'

const route = useRoute();
const router = useRouter();
const handleSelect = (key: string, keyPath: string[]) => {}

const backToHome = () => {
  router.push('/home');
}
</script>
  
<style scoped>
.menu-vertical .el-main {
  padding-left: 0;
  padding-right: 0;
}
.menu-vertical .el-header {
  padding: 0;
}
.menu-vertical .el-footer {
  padding: 0;
}
/* @media screen and (max-height: 400px) {
  .menu-text-container {
    display: none;
  }
} */
.footer-icon {
  color: #fffffe;
  margin-left: 17px;
  margin-right: 5px;
}
.menu-header {
  height: 106px;
}
.menu-footer {
  /* height: 100px; */
}
.footer-text {
  margin: 0;
  color:#fffffe;
  font-size: 14px;
  line-height: 14px;
  font-weight: 400;
  /* padding-left: 17px; */
  text-align: left;
}
h1 {
  color:#fffffe;
  font-size: 30px;
  font-weight: 700;
}
.menu-bot {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.menu-text {
  color:#fffffe;
  font-size: 18px;
  font-weight: 500;
  padding-left: 17px;
  text-align: left;
}
.menu-vertical {
  padding: 30px;
  height: 100%;
  width: 100%;
  background-color: #5200FF;
  box-shadow: 4px 0px 10px 0px rgba(0, 0, 0, 0.1);
}
.flex-grow {
  flex-grow: 1;
}
.el-menu-item {
  background-color: transparent !important;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: 500;
}
.el-menu-item.is-active {
  background-color: transparent !important;
  font-weight: 500;
}
.el-menu-item:hover{
    background-color: transparent !important;
    color: #eebbc3 !important;
    /* text-decoration: underline; */
}
.flex-grow {
  flex-grow: 1;
}
</style>
  