<template>
  <div class="tipping-panel" v-loading="loading" element-loading-background="rgba(245, 245, 245, 0.5)">
    <PendingTable v-if="data.value==='pending'" :value="tableData" @reloadEvent="handleReload"></PendingTable>
    <CompleteTable v-else-if="data.value==='complete'" :value="tableData"></CompleteTable>
    <!-- <div class="tipping-panel-header flex">
      <el-icon @click="init"><RefreshRight /></el-icon>
      <div class="panel-search flex">
        <el-input class="tipping-input" v-model="input" />
        <el-icon><Search /></el-icon>
      </div>
    </div>
    <div class="tipping-panel-content" v-loading="loading">
      <PendingTable v-if="data.value==='pending'" :value="tableData" @reloadEvent="handleReload"></PendingTable>
      <CompleteTable v-else-if="data.value==='complete'" :value="tableData"></CompleteTable>
    </div> -->
  </div>
  <div class="tipping-panel-footer flex">
    <div style="flex:1"/>
    <el-pagination v-if="data.value==='pending'"
    v-model:current-page="currentPage"
    layout="prev, pager, next" 
    :background="true" 
    :total="totalCount" 
    @current-change="handleCurrentChange"
    :hide-on-single-page="true"
    :default-page-size="size"
    />
    <el-pagination v-else-if="data.value==='complete'"
    v-model:current-page="currentPage"
    layout="prev, pager, next" 
    :background="true" 
    :total="totalCount" 
    @current-change="handleCurrentChange"
    :hide-on-single-page="true"
    :default-page-size="size"
    />
    <div style="flex:1"/>
  </div>
</template>

<script lang="ts" setup>
import { onBeforeMount, defineProps, ref } from 'vue'

import PendingTable from '@/components/order/PendingTable.vue'
import CompleteTable from '@/components/order/CompleteTable.vue'

import orderApi from '@/api/order'

const data = defineProps(['value']);

const input = ref();
const loading = ref(false);
const totalCount = ref(0);
const currentPage = ref(1);
const size = 13;

const tableData = ref([]);

const handleReload = () => {
  init(currentPage.value);
}

const handleCurrentChange = (page: number) => {
  loading.value = true;
  if (data.value==='pending') {

    orderApi.pendingOrders({page:page-1,size:size}).then(res => {
      if (res && res.code == 1) {
        tableData.value = res.response;
      }
      loading.value = false;
    }).catch(err => {
      loading.value = false;
    });

  }
  else {

    orderApi.completeOrders({page:page-1,size:size}).then(res => {
      if (res && res.code == 1) {
        tableData.value = res.response;
      }
      loading.value = false;
    }).catch(err => {
      loading.value = false;
    });

  }
}

const init = async (page:number) => {
  // currentPage.value = 1;
  loading.value = true;
  if (data.value==='pending') {

    orderApi.pendingCount().then(res => {
      if (res && res.code == 1) {
        totalCount.value = res.response;
      }
      // loading.value = false;
    }).catch(err => {
      // loading.value = false;
    });

    orderApi.pendingOrders({page:page-1,size:size}).then(res => {
      if (res && res.code == 1) {
        tableData.value = res.response;
        // console.log(res)
      }
      loading.value = false;
    }).catch(err => {
      loading.value = false;
    });

  }
  else {

    orderApi.completeCount().then(res => {
      if (res && res.code == 1) {
        totalCount.value = res.response;
      }
      // loading.value = false;
    }).catch(err => {
      // loading.value = false;
    });

    orderApi.completeOrders({page:page-1,size:size}).then(res => {
      if (res && res.code == 1) {
        tableData.value = res.response;
        // console.log(res.response);
      }
      loading.value = false;
    }).catch(err => {
      loading.value = false;
    });

  }
}

onBeforeMount( async () => {
  init(1);
});

</script>

<style scoped>
.tipping-panel-footer {
  margin-top: 30px;
}
.tipping-panel-content {
  margin-top: 30px;
}
.panel-search {
  width: 250px;
  justify-content: space-between;
}
.tipping-input {
  height: 35px;
  width: 200px;
}
.tipping-panel-header {
  padding: 0 15px;
  font-size: 25px;
  height: 50px;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: #fffffe;
  align-items: center;
  justify-content: space-between;
}
.tipping-panel {
  /* height: calc(100vh - 100px - 30px - 60px); */
  /* padding: 30px;
  border-radius: 5px;
  background-color: #fffffe; */
  border-radius: 20px;
}
.el-icon {
  background-color: #3DA9FC;
  border-radius: 5px;
  height: 35px;
  width: 35px;
  cursor: pointer;
}
</style>