<template>
  <div class="form-container flex">
    <el-form 
      label-position="left"
      :model="form"
      class="signup-form"
    >
      <h1 class="form-header flex" style="margin: 0 0 10px 5px; ">CONTACT</h1>
      <el-row :gutter="15" class="input-group">
        <el-col :span="8">
          <el-form-item label="" >
            <el-input class="form-input" v-model="form.phone" placeholder="Phone Number"/>
          </el-form-item>
        </el-col>
      </el-row>
      <h1 class="form-header flex" style="margin: 0 0 10px 5px; ">GENERAL INFO</h1>
      <el-row :gutter="15" class="input-group">
        <el-col :span="8">
          <el-form-item label="" >
            <el-input class="form-input" v-model="form.gender" placeholder="Gender"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="" >
            <el-date-picker
              v-model="form.birth"
              type="date"
              placeholder="Birth Date"
              style="height: 40px;"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <h1 class="form-header flex" style="margin: 0 0 10px 5px; ">ADDRESS</h1>
      <el-row :gutter="15" class="input-group">
        <el-col :span="8">
          <el-form-item label="" >
            <el-input class="form-input" v-model="form.address" placeholder="Address"/>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="" >
            <el-input class="form-input" v-model="form.city" placeholder="City" />
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-row :gutter="5">
            <el-col :span="12">
              <el-form-item label="" prop="state">
                <el-input class="form-input" v-model="form.state" placeholder="State"/>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="" prop="postcode">
                <el-input class="form-input" v-model="form.postcode" placeholder="Postcode"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-form>
    <div class="form-footer-container flex">
      <el-button type="primary" class="submit-btn" color="#5200FF" @click="emits('registerEvent', -1);">PREV</el-button>
      <el-button type="primary" class="submit-btn" color="#5200FF" @click="register">SAVE</el-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { Ref, ref, reactive } from 'vue'

const emits = defineEmits(['registerEvent']);

const form = reactive({
  phone: '',
  gender: '',
  birth: '',
  address: '',
  city: '',
  state: '',
  postcode: '',
});

const register = () => {
  emits('registerEvent', 1, form);
}
</script>

<style scoped>
.form-container {
  height: 320px;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.input-group {
  /* box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.05); */
  /* background-color: rgba(144, 180, 206, 0.15); */
  /* border-radius: 5px;
  margin-bottom: 30px;
  padding: 10px 15px; */
  /* width: 100%; */
}
.submit-btn {
  width: 180px;
  height: 50px;
  font-size: 18PX;
  font-weight: 500;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  /* color: #F5F5F5; */
  border-radius: 12px;
}
.form-footer-container {
  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
}
.form-header {
  color: #161616;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  margin: 0;
  text-align: left;
  align-items: flex-end;
}
.join-form-row {
  justify-content: space-between;
}
.form-address-input {
  height: 35px;
  margin-right: 15px;
  /* width: 100px; */
}
.form-input {
  height: 40px;
  /* margin-right: 15px; */
  /* width: 150px; */
}
.form-input-text {
  margin: 0 0 15px 0;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  text-align: left;
}
.signup-form {
  width: 100%;
}
</style>