<template>
  <el-row :gutter="30" style="width: 100%;height: 100%;">
    <el-col :span="12">
      <div class="ladder-container">
        <div class="panel-header-container flex">
          <h1 class="panel-header flex">
            AFL Teams
          </h1>
        </div>
        <div class="panel-content-container flex" v-loading="loading" element-loading-background="rgba(245, 245, 245, 0.3)">
          <VueDraggable
            v-model="teams1"
            :animation=150
            ghostClass="ghost"
            group="teams"
            @add="balanceTeam"
            style="width: 45%;"
          >
            <TeamCard v-for="item in teams1" :value="item" :key="item.id"></TeamCard>
          </VueDraggable>

          <VueDraggable
            v-model="teams2"
            :animation=150
            ghostClass="ghost"
            group="teams"
            @add="balanceTeam"
            style="width: 45%;"
          >
            <TeamCard v-for="item in teams2" :value="item" :key="item.id"></TeamCard>
          </VueDraggable>
        </div>
      </div>
    </el-col>
    <el-col :span="12">
      <div class="ladder-container">
        <div class="panel-header-container flex">
          <h1 class="panel-header flex">
            My Ladder
          </h1>
          <el-button class="save-button" color="#5200FF" @click="submit">Save Ladder</el-button>
        </div>
        <div class="panel-content-container flex" v-loading="loading" element-loading-background="rgba(245, 245, 245, 0.3)">
          <VueDraggable
            v-model="teams3"
            :animation=150
            ghostClass="ghost"
            group="teams"
            @add="balanceTeam"
            style="width: 45%;"
          >
            <TeamCard v-for="(item, index) in teams3" :value="item" :index="index+1" :key="item.id"></TeamCard>
          </VueDraggable>

          <VueDraggable
            v-model="teams4"
            :animation=150
            ghostClass="ghost"
            group="teams"
            @add="balanceTeam"
            style="width: 45%;"
          >
            <TeamCard v-for="(item, index) in teams4" :value="item" :index="index+10" :key="item.id"></TeamCard>
          </VueDraggable>
        </div>
        <!-- <div class="panel-footer-container flex">
          <el-button type="primary" class="submit-btn" color="#3DA9FC" @click="submit">SUBMIT</el-button>
        </div> -->
      </div>
    </el-col>
  </el-row>


  <!-- <div class="tip-text flex">
    Drag teams here
    <el-icon style="font-size: 14px;"><Right /></el-icon>
  </div> -->


</template>

<script lang="ts" setup>
import { onBeforeMount, nextTick, ref, reactive } from 'vue'
import { VueDraggable } from 'vue-draggable-plus'
import { ElMessage, ElMessageBox } from 'element-plus'
import type { Action } from 'element-plus'

import TeamCard from '@/components/ladder/TeamCard.vue'

import preferenceApi from '@/api/ladder'

const loading = ref(false);

const teams1 = ref([
  {
    id:1,
    abbrev:"ADE"
  },
  {
    id:2,
    abbrev:"BRI"
  },
  {
    id:3,
    abbrev:"CAR"
  },
  {
    id:4,
    abbrev:"COL"
  },
  {
    id:5,
    abbrev:"ESS"
  },
  {
    id:6,
    abbrev:"FRE"
  },
  {
    id:7,
    abbrev:"GEE"
  },
  {
    id:8,
    abbrev:"GCS"
  },
  {
    id:9,
    abbrev:"GWS"
  },

]);
const teams2 = ref([
  {
    id:10,
    abbrev:"HAW"
  },
  {
    id:11,
    abbrev:"MEL"
  },
  {
    id:12,
    abbrev:"NOR"
  },
  {
    id:13,
    abbrev:"POR"
  },
  {
    id:14,
    abbrev:"RIC"
  },
  {
    id:15,
    abbrev:"STK"
  },
  {
    id:16,
    abbrev:"SYD"
  },
  {
    id:17,
    abbrev:"WCE"
  },
  {
    id:18,
    abbrev:"WBD"
  },
]);

const teams3:any = ref([]);
const teams4:any = ref([]);

const submit = () => {
  if (teams1.value.length == 0 && teams2.value.length == 0) {
    let query = [];
    let len3 = teams3.value.length, len4 = teams4.value.length;
    let totalTeams = len3 + len4;

    for (let i = 0; i < totalTeams; i++) {
      let t = i < len3 ? teams3.value[i] : teams4.value[i - len3];
      let q = {
        tid: t.id,
        preference: i
      };
      query.push(q);
    }

    preferenceApi.save(query).then(res => {
      if (res && res.code == 1) {
        ElMessage({
          type: 'success',
          message: 'Submit completed',
        });
      }
      else {
        ElMessage({
          type: 'error',
          message: res.message,
        });
      }
    }).catch(err => {
      ElMessage({
        type: 'error',
        message: err.message,
      });
      // console.log(err);
    });
  }
  else {
    ElMessageBox.alert('Please drag all teams to your ladder', 'Rejected', {
      // if you want to disable its autofocus
      // autofocus: false,
      confirmButtonText: 'OK',
      callback: (action: Action) => {
      }
    });
  }
}

const init = () => {
  loading.value = true;
  preferenceApi.preference().then(res => {
    if (res && res.code == 1) {
      let len = res.response.length;
      teams1.value = [];
      teams2.value = [];
      for (let i = 0; i < len/2; i++) {
        teams3.value.push(res.response[i]);
      }
      for (let i = len/2; i < len; i++) {
        teams4.value.push(res.response[i]);
      }
    }
    loading.value = false;
  }).catch(err => {
    loading.value = false;
  })
}

const balanceTeam = async () => {
  await nextTick();

  if (teams1.value.length > 9) {
    let t = teams1.value.pop();
    teams2.value.unshift(t!);
  }
  else if (teams2.value.length > 9) {
    let t = teams2.value.shift();
    teams1.value.push(t!);
  }
  else if (teams3.value.length > 9) {
    let t = teams3.value.pop();
    teams4.value.unshift(t!);
  }
  else if (teams4.value.length > 9) {
    let t = teams4.value.shift();
    teams3.value.push(t!);
  }
  
  if (teams4.value.length > 0 && teams3.value.length < 9) {
    let t = teams4.value.shift();
    teams3.value.push(t!);
  }

}

onBeforeMount(async () => {
  init();
})
</script>

<style scoped>
.save-button {
  width: 120px;
  height: 30px;
  font-size: 18x;
  font-weight: 500;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  color: #F5F5F5;
  border-radius: 12px;
}
.tip-text {
  height: 150px;
  color: #5F6C7B;
  font-size: 16px;
  line-height: 16px;
  font-weight: 300;
  margin: 0;
  text-align: left;
  align-items: flex-end;
  user-select: none;
}
.submit-btn {
  width: 120px;
  height: 35px;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 4px 10px 1px rgba(0, 0, 0, 0.1);
  color: #fffffe;
}
.panel-footer-container {
  padding: 5px 0 15px 15px;
  /* border-top: 2px solid #D9D9D9; */
}
.panel-content-container {
  padding: 15px 15px;
  height: calc(100% - 35px - 30px - 50px);
  justify-content: space-between;
}
.panel-header-container {
  padding: 15px 30px 7.5px 30px;
  height: 30px;
  /* border-bottom: 2px solid #D9D9D9; */
  justify-content: space-between;
}
.panel-header {
  color: #161616;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  text-align: left;
  align-items: center;
}
.ladder-container {
  /* width: 45%; */
  /* height: 605px; */
  height: 100%;
  background-color: #F5F5F5;
  border-radius: 20px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
}
</style>