<template>
  <div class="panel-header-container flex">
    <h1 class="panel-header flex">
      Upcoming Comps
    </h1>
    <h1 class="panel-header-link flex" @click="router.push('/current-tipping')">
      <!-- check complete ladder
      <el-icon style="font-size: 10px;"><Right /></el-icon> -->
      <el-icon><FullScreen /></el-icon>
    </h1>
  </div>

  <div class="panel-content-container">
    <el-scrollbar>
      <UpcomingCompetition v-for="comp in competitions" :value="comp"></UpcomingCompetition>
    </el-scrollbar>
  </div>

</template>

<script lang="ts" setup>
import { onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import UpcomingCompetition from '@/components/dashboard/UpcomingCompetition.vue'

import competitionApi from '@/api/competition'

const route = useRoute();
const router = useRouter();

const emits = defineEmits(['compLoadEvent']);

const competitions = ref([
]);

onBeforeMount( async () => {
  competitionApi.activeNotNullCompetitions({page:0,size:10}).then(res => {
    if (res && res.code == 1) {
      competitions.value = res.response;
    }
    emits('compLoadEvent');
  }).catch(err => {
    emits('compLoadEvent');
  })
});
</script>

<style scoped>
.panel-content-container {
  padding: 15px 0 7.5px 0;
  height: calc(100% - 60px - 32px);
}
.panel-header-container {
  padding: 30px 30px 10px 30px;
  height: 20px;
  /* border-bottom: 2px solid #D9D9D9; */
  justify-content: space-between;
}
.panel-header {
  color: #161616;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  text-align: left;
  align-items: flex-end;
}
.panel-header-link {
  /* color: #5F6C7B; */
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  text-align: left;
  align-items: flex-end;
  cursor: pointer;
}
</style>