import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import DashboardView from '../views/DashboardView.vue'
import LadderView from '../views/LadderView.vue'
import CurrentTippingView from '../views/CurrentTippingView.vue'
import HistoryTippingView from '../views/HistoryTippingView.vue'
import TipView from '../views/TipView.vue'
import TipTransitView from '../views/TipView.vue'
import ResultView from '../views/ResultView.vue'
import CurrentCompetitionView from '../views/CurrentCompetitionView.vue'
import HistoryCompetitionView from '../views/HistoryCompetitionView.vue'
import JoinCompetitionView from '../views/JoinCompetitionView.vue'
import OrderCompleteView from '../views/OrderCompleteView.vue'
import OrderPendingView from '../views/OrderPendingView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: HomeView
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView
  },
  {
    path: '/current-tipping',
    name: 'Current Tipping',
    component: CurrentTippingView
  },
  {
    path: '/history-tipping',
    name: 'History Tipping',
    component: HistoryTippingView
  },
  {
    path: '/tip/:tip',
    name: 'Tipping Game',
    component: TipView,
    // children:[
    //   {path: ':tip/:round',component: TipView}
    // ]
  },
  // {
  //   path: '/tip/:tip',
  //   name: 'Tipping Transit',
  //   component: TipTransitView,
  //   // children:[
  //   //   {path: ':tip/:round',component: TipView}
  //   // ]
  // },
  {
    path: '/result/:tip',
    name: 'Comp Result',
    component: ResultView,
    // children:[
    //   {path: ':tip/:round',component: TipView}
    // ]
  },
  {
    path: '/current-competition',
    name: 'Current Comp',
    component: CurrentCompetitionView
  },
  {
    path: '/archive',
    name: 'Comp Archive',
    component: HistoryCompetitionView
  },
  {
    path: '/join/:competition',
    name: 'Join Comp',
    component: JoinCompetitionView
  },
  {
    path: '/ladder',
    name: 'My Ladder',
    component: LadderView
  },
  {
    path: '/pending-order',
    name: 'Pending Order',
    component: OrderPendingView
  },
  {
    path: '/complete-order',
    name: 'Complete Order',
    component: OrderCompleteView
  },
  {
    path: '/setting',
    name: 'My Setting',
    component: DashboardView
  },
  
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    return { top: 0 }
  }
})

export default router
